import React, {useState, useEffect} from "react";
import { view } from "@risingstack/react-easy-state";
import { Container, Table, Button } from "react-bootstrap";
import "../components/layout/layout.css";
import styled from 'styled-components';
import callBackend from "../helpers";

const StyledContainerTable = styled(Container)`
    font-size: 14px;
    width: 100%;
    height: 100%;

    img {
      max-width: 100px;
      max-height: 100px;

    }

    .container {
      width: 960px;
      height: 500px;
    }

    .table-container {
      margin: 20px 0 20px 0;
      border-radius: 20px;
      max-height: 400px;
      padding: 0 10px 10px 10px;
      overflow: scroll;
    }

    .table {
      text-align: center;
    }

    .table tbody tr {
      :hover {
          background-color: #f8e6bf;
        }
    }

    .table td {
      vertical-align: middle;
    }

    .table thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #274C77;
      color: whitesmoke;
    }

    p {
      color: black !important;
      text-align: left;
    }

    a {
      color: #17a2b8;
      font-weight: 500;

      :hover {
        color: black;
      }
    }

    .action-button {
        font-size: small;
        background-color: #28a745;
        border-color: #28a745;

        :hover {
            background-color: #3DAF57;
            border-color: #3DAF57;
        }
    }

    .block {
      background-color: #f84c23;
      border-color: #f84c23;

      :hover {
          background-color: #f95e39;
          border-color: #f95e39;
      }
    }
`;

function Admin() {

  const [users, setUsers] = useState([]); 
  const [organizations, setOrganizations] = useState([]);

  const token = window.localStorage.getItem('userToken')
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

  async function getAllUsers() {
    let users = await callBackend({ method: 'GET', url: 'users/allAdmin', headers: headers }) || [];
      if (users.error) {
        alert("Ajaj, stala se chyba při načítání uživatelů");
        console.log(users)
      } else {
        setUsers(users)
      }
      let organizations = await callBackend({ method: 'GET', url: 'organizations/allAdmin', headers: headers }) || [];
      if (organizations.error) {
        alert("Ajaj, stala se chyba při načítání organizací");
        console.log(organizations)
      } else {
        setOrganizations(organizations)
      }
  }

  useEffect(() => {
    getAllUsers();
  }, [])

  async function blockUser(userId) {
    const data = await callBackend({ method: 'POST', url: 'users/block', headers: headers, payload: {id: userId} });
    if (data.error) {
      alert('Ups, nepovedlo se')
      console.log(data);
    } else {
      let updatedUser = users.find((x) => x.id === userId)
      updatedUser.level = 0
      setUsers(users => ({
        ...users,
        ...updatedUser
      }));
      alert('Uživatel zablokován')
    }
  }

  async function allowUser(userId) {
    const data = await callBackend({ method: 'POST', url: 'users/allow', headers: headers, payload: {id: userId} });
    if (data.error) {
        alert('Ups, nepovedlo se')
      console.log(data);
    } else {
      let updatedUser = users.find((x) => x.id === userId)
      // it might be even 2 or 3 but for the purpose of button change, 1 is enough
      updatedUser.level = 1
      setOrganizations(users => ({
        ...users,
        ...updatedUser
      }));
    }
  }

  async function blockOrganization(orgId) {
    const data = await callBackend({ method: 'POST', url: 'organizations/block', headers: headers, payload: {id: orgId} });
    if (data.error) {
      alert('Ups, nepovedlo se')
      console.log(data);
    } else {
      let updatedOrg = organizations.find((x) => x.id === orgId)
      updatedOrg.level = 0
      setOrganizations([...organizations]);
      alert('Uživatel zablokován')
    }
  }

  async function allowOrganization(orgId) {
    const data = await callBackend({ method: 'POST', url: 'organizations/allow', headers: headers, payload: {id: orgId} });
    if (data.error) {
        alert('Ups, nepovedlo se')
      console.log(data);
    } else {
      let updatedOrg = organizations.find((x) => x.id === orgId)
      // it might be even 2 or 3 but for the purpose of button change, 1 is enough
      updatedOrg.level = 1
      setOrganizations([...organizations]);
      alert('Uživatel odblokován')
    }
  }

  let usersRows = []
  function getUsersRows(users) {
    usersRows = users?.map((user, index) => <tr key={index}>
            <td><img src={user.profilePictureUrl}/></td>
            <td>{user.email}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.info}</td>
            {user.level > 0 
              ? <td><Button className="action-button block" onClick={() => blockUser(user.id)}>Zablokovat</Button></td>
              : <td><Button className="action-button" onClick={() => allowUser(user.id)}>Odblokovat</Button></td>
            }
        </tr>)
      || null;
    return usersRows
  }

  getUsersRows(users)

  useEffect(()=>{
    getUsersRows(users)
  }, [users])
  
  let orgRows = []

  function getOrgRows(organizations) {
    orgRows = organizations?.map((user, index) => <tr key={index}>
            <td><img src={user.profilePictureUrl}/></td>
            <td>{user.email}</td>
            <td>{user.title}</td>
            <td>{user.info}</td>
            {user.level > 0 
              ? <td><Button className="action-button block" onClick={() => blockOrganization(user.id)}>Zablokovat</Button></td>
              : <td><Button className="action-button" onClick={() => allowOrganization(user.id)}>Odblokovat</Button></td>
            }
        </tr>)
      || null;
    return orgRows
  }

  getOrgRows(organizations)

  useEffect(()=>{
    getOrgRows(organizations)
  }, [organizations])
  
  return (
    <StyledContainerTable>
        <h4>Users</h4>
        <div className='table-container'>
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Foto</th>
                    <th>Email</th>
                    <th>Jméno</th>
                    <th>Příjmení</th>
                    <th>Info</th>
                    <th>Správa přístupu</th>
                </tr>
                </thead>
                <tbody>
                    {usersRows}
                </tbody>
            </Table>
        </div>

        <h4>Organizations</h4>
        <div className='table-container'>
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Foto</th>
                    <th>Email</th>
                    <th>Název</th>
                    <th>Info</th>
                    <th>Správa přístupu</th>
                </tr>
                </thead>
                <tbody>
                    {orgRows}
                </tbody>
            </Table>
        </div>
    </StyledContainerTable>
    );
  }

export default view(Admin);
