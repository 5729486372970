import React from "react";
import "./layout.css";
import { useHistory, Link } from "react-router-dom";
import appStore from "../../appStore";
import { view } from "@risingstack/react-easy-state";
import logo from "./sousede_logo.png";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHouse, 
  faEarth, 
  faCalendarDays, 
  faUser,
  faRightFromBracket,
  faPlus,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import  LanguageSwitcher from "../../components/layout/languageSwitcher";
// import { useTranslate } from '../../translate';

function Layout({ children }) {

  const history = useHistory();
  // const { t } = useTranslate();

  function logout() {
    appStore.user = {};
    window.localStorage.clear();
    history.push({ pathname: "/login" });
  }

  return (
  <div className="mainContainer">
      <header>
        <div className="header">
            <div className="topnav">
                  {!appStore.user.id && (
                    <ul className="navbar-nav ml-auto ">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          {" "}
                          Úvod{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/registrace">
                          {" "}
                          Registrace{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/login">
                          {" "}
                          Login{" "}
                        </Link>
                      </li>
                    </ul>
                  )}
                  {appStore.user.id && (
                    <><Link className="navbar-brand" to="/home">
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="LOGO"
                        width="100" />
                      <p>SENIORE APP</p>
                    </Link>
                    <div className="mt-1">
                      <img src='EU_logo.jpg' width="300" height="64" alt="Evropský fond pro regionální rozvoj"/>
                      <img src='SNCZ_logo.jpg' width="90" height="64" alt="Sousede Nachbarn"/>
                    </div>
                    <ul className="nav navbar-nav ml-auto ">
                  <li className="nav-item">
                    <Link className="nav-link" to="/home">
                      <FontAwesomeIcon 
                        icon={faHouse} 
                        className="mr-3 hover"/>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/map">
                      <FontAwesomeIcon 
                        icon={faEarth} 
                        className="mr-3"/>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/events">
                      <FontAwesomeIcon 
                        icon={faCalendarDays} 
                        className="mr-3"/>
                    </Link>
                  </li>
                  {!appStore.user.isOrg && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/profil-uzivatel">
                        <FontAwesomeIcon 
                          icon={faUser} 
                          className="mr-3"/>
                      </Link>
                    </li>
                  )}
                  {appStore.user.isOrg && (
                    <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/profil-organizace">
                        <FontAwesomeIcon 
                          icon={faUser} 
                          className="mr-3"/>
                      </Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/create-event">
                     <FontAwesomeIcon 
                       icon={faPlus}
                       className="mr-3"/> 
                    </Link>
                   </li>
                   </>
                  )}
                  {appStore.user.level === 3 && (
                    <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/admin">
                        <FontAwesomeIcon 
                          icon={faScrewdriverWrench} 
                          className="mr-3"/>
                      </Link>
                    </li>
                   </>
                  )}
                  <li className="nav-item">
                    <LanguageSwitcher></LanguageSwitcher>
                  </li>
                  <li>
                    <Button className="btn logout" >
                      <FontAwesomeIcon 
                        icon={faRightFromBracket} 
                        onClick={logout}/>
                    </Button>
                  </li>
                </ul></>
                  )}
            </div>
          </div>
      </header>

      <main className="mt-5">    
        {children}
      </main>

      {/* <footer className="footer">
        <p className="col text-middle">
          {" "}
          © {new Date().getFullYear()} Copyright - Seniore
        </p>
      </footer> */}
  </div>
  );
}

export default view(Layout);
