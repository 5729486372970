import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  margin-top: 60px;
  background-color: #E3A51A !important;

  .logo_languageSwitcher {
    width: 100%;
    margin-top: 10px
  }

  .logos {
    background-color: white;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 45px;
    color: #4F4F4F;
    letter-spacing: 3px;
  }

  .subtitle {
    color: #4F4F4F;
    margin-bottom: 20px;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA;
    color: white;

    :hover {
      background-color: #76AFB2;
    }
  }

  button {
    border-radius: 15px;
  }

  input {
    border-radius: 15px;
  }

  .google-button {
    margin-left: 10px;
    background-color: #bdbdbd;
    color: black;
    border-color: white;

    :hover {
      background-color: #9e9e9e;
    }
  }

  .facebook-button {
    margin-left: 10px;
    background-color: #bdbdbd;
    color: white;
    border-color: white;

    :hover {
      background-color: #9e9e9e;
    }
  }
`;

export default StyledCard