import React from "react";
import { Link } from "react-router-dom";

export default function TermsOfUse() {
  return (
    <div className="m-3">

      <h5>Für Deutsch, bitte scrollen Sie nach unten</h5>

      <h3>Podmínky používání služby Seniore</h3>

        <ol>
          <li>
            Definice
            <ol>
              <li>
                <strong>Provozovatelem</strong> služby Seniore (dále také
                „služba“) je Seniore, IČ 08025452 s.r.o., se sídlem Bratislavská
                2323, 407 47 Varnsdorf, zapsaná v obchodním rejstříku vedeném
                Krajským soudem v Ústí nad Labem, oddíl C, vložka 42420.
              </li>
              <li>
                {" "}
                <strong>Služba</strong> Seniore je software, který je dostupný z
                webové stránky{" "}
                <Link to="http://www.seniore.org">seniore.org</Link>.
                <ol>
                  <li>
                    Využívání služby Seniore je zdarma a řídí se pravidly, která
                    jsou upravena těmito podmínkami.
                  </li>
                  <li>
                    Hlavní funkcionalitou služby je propojení seniorů a
                    dobrovolníků za účelem pomoci.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Uživatelem</strong> služby je jakákoliv fyzická nebo
                právnická osoba odlišná od provozovatele, která aplikaci Seniore
                využívá.
              </li>
            </ol>
          </li>
        </ol>
        <ol start="2">
          <li>
            Účel služby
            <ol>
              <li>
                Účelem služby je pomoci propojit dobrovolníky se seniory, kteří
                potřebují pomoci. Služba funguje jako komunitní platforma, kde
                se seznámí senioři s někým blízkým z jejich okolí, kdo může
                vypomoci.
              </li>
              <li>
                Pomoc v rámci služby je definována jako pomoc amatérského
                charakteru. V rámci služby je možné nabízet pouze takové
                činnosti, k nimž není potřeba žádné osvědčení. Nabízení ani
                poskytování lékařských, rehabilitačních a dalších obdobných
                služeby profesionálního charakteru není v Seniore dovoleny.
              </li>
              <li>
                V rámci služby Seniore je ZAKÁZÁNO provádět jakékoliv činnosti
                za účelem zisku. Služba je neplacená a nikdo z uživatelůnení
                oprávněn požadovat jakoukoliv finanční náhradu, kromě náhrady,
                která pokryje jím vynaložené náklady (například pořízení
                nákupu), což doloží stvrzenkou. Výše těchto nákladů nesmí
                přesáhnout 500,- Kč, slovy pět set korun českých.
              </li>
              <li>
                Služba Seniore slouží jako prostředek pro propojení lidí mezi
                sebou. Nebere na sebe rizika tohoto propojení. Než se spojíte,
                zajistěte svou bezpečnost. V žádném případě nepouštějte cizí
                lidi k sobě domů. Nemějte u sebe hotovost vyšší, než 500 Kč.
              </li>
              <li>
              Služba dále funguje jako nástroj vedoucí k posílení a rozvoji
              přeshraniční oblasti a přeshraničních vztahů. Tyto vztahy mohou mimo 
              jiné vznikat mezi uživateli a neziskovými organizacemi. Pomocí služby
              se mohou uživatelé registrovat na akce organizací zapojených do 
              služby Seniore. 
              Registrací do služby beru na vědomí, že se stávám součástí sítě projektu Svět,
              kde si sousedé pomáhají. Členství v síti je bezplatné a nevyplývají z něj žádné 
              povinnosti. Ze sítě je možné kdykoliv vystoupit.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="3">
          <li>
            Užívání služby
            <ol>
              <li>
                Provozovatel uděluje Uživateli licenci k využití služby v
                rozsahu a za podmínek uvedených v uživatelském prostředí služby.
              </li>
              <li>Provozovatel uděluje Uživateli licenci bezúplatně.</li>
              <li>Uživatel má právo zřídit pouze jeden uživatelský účet.</li>
              <li>
                Provozovatel má právo službu nebo její část kdykoliv
                znepřístupnit, odstavit a/nebo ji znepřístupnit z příslušné
                internetové adresy, a to z jakéhokoliv důvodu.
              </li>
              <li>
                Provozovatel je oprávněn omezit počet Uživatelů a odmítnout
                registraci jakéhokoliv Uživatele bez udání důvodu
              </li>
              <li>
                Provozovatel má právo vymazat profil Uživatele z důvodu porušení
                ustanovení těchto podmínek.
              </li>
              <li>
                Uživatel se zavazuje při užívání služby jednat tak, aby jemu,
                Provozovateli ani dalším Uživatelům služby nevznikla žádná
                škoda.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="4">
          <li>
            Zobrazování údajů
            <ol>
              <li>
                Služba propojuje jednotlivé Uživatele mezi sebou. Aby se mohli
                spojit, zobrazí jejich jméno, přibližnou lokaci a emailovou
                adresu. Registrací do služby souhlasíte s tím, že tyto údaje
                budou zobrazeny ostatním Uživatelům.
              </li>
              <li>
                Pro účely bezpečnosti je vyžadováno poskytnutí příjmení a scan
                občanského průkazu (na kterém je možné zakrýt citlivé údaje).
                Tyto údaje jsou po dobu využívání služby Uživatelem uchovány v
                databázi Seniore. Nejsou ovšem viditelné běžným Uživatelům
                Seniore ani žádné třetí straně.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="5">
          <li>
            Ochrana osobních údajů
            <ol>
              <li>
                Provozovatel služby je ze zákona povinen chránit a zabezpečit
                poskytnuté údaje. Provozovatel proto využívá efektivní
                zabezpečovací technologie ktomu, aby byly osobní údaje chráněny
                před neoprávněným zpřístupněním nebo zneužitím.
              </li>
              <li>
                Podrobnější informace o ochraně osobních údajů naleznete v{" "}
                <Link to="/podminky-vyuziti-osobnich-udaju">
                  Zásadách ochrany osobních údajů
                </Link>
                .
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="6">
          <li>
            <strong>Závěrečná ustanovení</strong>
            <ol>
              <li>
                Registrací do služby Seniore Uživatel souhlasí s výše uvedenými
                podmínkami.
              </li>
            </ol>
          </li>
        </ol>

        <p className="textLeft">
          {" "}
          <b>
            Zpět na <Link to="/registrace">registraci </Link>.
          </b>
        </p>

        <hr></hr>

      <h3>Seniore Allgemeine Geschäftsbedingungen</h3>

        <ol>
          <li>
          Definition
            <ol>
              <li>
                <strong>Veranstaltet</strong> Services Seniore (im Folgenden auch
                "Dienst") ist Seniore, ID 08025452 s.r.o., mit Sitz in Bratislavská
                2323, 407 47 Varnsdorf, eingetragen in das Handelsregister der
                Landgericht in Ústí nad Labem, Abteilung C, Einlage 42420.
              </li>
              <li>
                {" "}
                <strong>Service</strong> Seniore ist eine Software, die erhältlich ist bei
                Website{" "}
                <Link to="http://www.seniore.org">seniore.org</Link>.
                <ol>
                  <li>
                    Die Nutzung des Seniore-Dienstes ist kostenlos und unterliegt den folgenden Regeln
                    werden durch diese Bedingungen geregelt.
                  </li>
                  <li>
                    Die Hauptfunktion des Dienstes ist die Verbindung von Senioren und
                    Freiwillige, die helfen.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Unter</strong> Dienstleistungen ist jede natürliche oder
                andere juristische Person als der Betreiber, der die Seniore-Anwendung nutzt
                verwendet.
              </li>
            </ol>
          </li>
        </ol>
        <ol start="2">
          <li>
            Zweck des Dienstes
            <ol>
              <li>
                Ziel des Dienstes ist es, Freiwillige mit älteren Menschen zusammenzubringen, die
                die Hilfe benötigen. Der Dienst funktioniert als Gemeinschaftsplattform, auf der
                Senioren treffen jemanden, der ihnen nahe steht und
                helfen.
              </li>
              <li>
                Assistenz im Rahmen des Dienstes ist definiert als die Unterstützung durch einen Amateur
                Natur. Im Rahmen des Dienstes dürfen nur die folgenden Leistungen angeboten werden
                Tätigkeiten, für die keine Bescheinigung erforderlich ist. Weder Angebot noch
                die Erbringung von medizinischen, rehabilitativen und anderen ähnlichen Leistungen
                Dienstleistungen professioneller Art sind bei Seniore nicht erlaubt.
              </li>
              <li>
                V rámci služby Seniore je ZAKÁZÁNO provádět jakékoliv činnosti
                za účelem zisku. Služba je neplacená a nikdo z uživatelůnení
                oprávněn požadovat jakoukoliv finanční náhradu, kromě náhrady,
                která pokryje jím vynaložené náklady (například pořízení
                nákupu), což doloží stvrzenkou. Výše těchto nákladů nesmí
                přesáhnout 500,- Kč, slovy pět set korun českých.
              </li>
              <li>
                Es ist VERBOTEN, innerhalb des Seniore-Dienstes irgendwelche Aktivitäten durchzuführen
                für Profit. Der Dienst ist unbezahlt und keiner der Nutzer ist
                einen Anspruch auf eine andere finanzielle Entschädigung als die Erstattung hat,
                zur Deckung der anfallenden Kosten (z. B. für den Kauf von
                Kauf), was er durch eine Quittung nachweisen muss. Die Höhe dieser Kosten darf nicht
                500 CZK, also fünfhundert tschechische Kronen, überschreiten.
              </li>
              <li>
              Der Dienst funktioniert weiter und verkstärkt die Entwicklung des grenzüberschreiten Raums und Beziehungen. 
              Diese Beziehungen können auch zwischen Benutzern und gemeinnützigen Organisationen entstehen.Dank dieses 
              Dienstes können sich Benutzern registrieren fůr die Veranstaltung des Seniordienstes anmelden!
              Ich habe mich registriert,ich bin im Projekt namens. Welt,wo die Nachbaren sich helfen. 
              Mitgliedschaft ist kostenlos,es gibt keine Verpflichtungen,ich kann jederzeit austreten.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="3">
          <li>
            Nutzung des Dienstes
            <ol>
              <li>
                Der Betreiber gewährt dem Nutzer eine Lizenz zur Nutzung des Dienstes in
                in dem Umfang und zu den Bedingungen, die in der Benutzerumgebung des Dienstes angegeben sind.
              </li>
              <li>Der Betreiber gewährt dem Nutzer eine kostenlose Lizenz.</li>
              <li>Ein Benutzer darf nur ein Benutzerkonto anlegen.</li>
              <li>
                Der Betreiber hat das Recht, den Dienst oder einen Teil davon jederzeit zu nutzen
                zu deaktivieren, abzuschalten und/oder nicht mehr verfügbar zu machen
                Internetadresse, aus welchem Grund auch immer.
              </li>
              <li>
                Der Betreiber ist berechtigt, die Anzahl der Nutzer zu begrenzen und zu verweigern
                die Registrierung eines Benutzers ohne Angabe von Gründen
              </li>
              <li>
                Der Betreiber hat das Recht, das Profil des Nutzers zu löschen, wenn dieser gegen folgende Bestimmungen verstößt
                Bestimmungen der vorliegenden Bedingungen.
              </li>
              <li>
                Der Nutzer verpflichtet sich, so zu handeln, dass er/sie,
                dem Betreiber oder anderen Nutzern des Dienstes keine Kosten entstehen
                Schaden.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="4">
          <li>
            Anzeige der Daten
            <ol>
              <li>
                Der Dienst verbindet die einzelnen Nutzer miteinander. Um in der Lage zu sein
                eine Verbindung herstellen, werden ihr Name, ihr ungefährer Standort und ihre E-Mail-Adresse angezeigt.
                Adresse. Mit der Registrierung für den Dienst stimmen Sie zu, dass die folgenden Informationen
                wird anderen Benutzern angezeigt.
              </li>
              <li>
                Aus Sicherheitsgründen müssen Sie Ihren Nachnamen und Ihren Scan angeben
                Personalausweis (auf dem sensible Daten unkenntlich gemacht werden können).
                Diese Daten werden auf dem Computer des Nutzers für die Dauer der Nutzung des Dienstes gespeichert.
                Datenbank Seniore. Sie sind jedoch für den normalen Benutzer nicht sichtbar.
                Seniore oder einer dritten Partei.
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="5">
          <li>
            Schutz personenbezogener Daten
            <ol>
              <li>
                Der Dienstanbieter ist gesetzlich verpflichtet, Folgendes zu schützen und zu sichern
                die bereitgestellten Daten. Der Anbieter nutzt daher effektive
                Sicherheitstechnologien, um den Schutz personenbezogener Daten zu gewährleisten
                vor unbefugter Offenlegung oder Missbrauch.
              </li>
              <li>
                Ausführlichere Informationen zum Datenschutz finden Sie unter{" "}
                <Link to="/podminky-vyuziti-osobnich-udaju">
                  Datenschutzbestimmungen
                </Link>
                .
              </li>
            </ol>
          </li>
        </ol>
        <p></p>
        <ol start="6">
          <li>
            <strong>Schlussbestimmungen</strong>
            <ol>
              <li>
                Mit der Registrierung für den Seniore-Dienst erklärt sich der Nutzer mit den oben genannten Bedingungen einverstanden.
                Bedingungen und Konditionen.
              </li>
            </ol>
          </li>
        </ol>

        <p className="textLeft">
          {" "}
          <b>
            Zurück zu <Link to="/registrace">Anmeldung </Link>.
          </b>
        </p>
    </div>
  );
}
