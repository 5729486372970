import React, { Component, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker, UsersCards} from "../components/layout/Marker";
import { Container, Row, Col } from "react-bootstrap";
import callBackend from "../helpers";
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { useTranslate } from '../translate';
import { setCurrentLanguage } from '../translate/Translate';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";


const StyledContainer = styled(Container)`
  .form-check {
    font-size: 12px;
  }
  .form-label {
    font-size: 14px;
    font-weight: bold;
  }
  .first-row {
    margin-top: 10px;
  }
  .users-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
    gap: 10px;
  }
  .card {
    background-color: #E7ECEF;
    border-radius: 15px;
    width: calc((100% / 3) - 30px);

    &:hover {
      background-color: #f8e6bf;
    }
  }
  .card-header {
    font-size: medium;
    color: #6EB6BA;
    font-weight: bold;
  }
  .card-text {
    font-size: small;
    text-align: left;

    b {
      color: #6EB6BA;
    }
  }

  .alert-heading {
    font-size: 1.2rem;
  }

  .alert {
    text-align: center;

    p {
      font-size: 1rem;
      margin-bottom: 0px;
    }

    a {
      font-size: 1rem;
    }
  }
`;

const isInBounds = (marker, bounds) =>
  {
    const latitude = parseFloat(marker.latitude);
    const longitude = parseFloat(marker.longitude);
    return longitude>bounds[0] && longitude<bounds[2]
          && latitude>bounds[1] && latitude<bounds[3];
  }

// is it ok like this? can´t anybody come and just write console.log(key)?
// set up in google console which app can use this key
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
class SimpleMap extends Component {

  constructor() {
    super();
    this.state = { 
      data: [],
      user: appStore.user,
      showAlert: false,
    };
  }

  async componentDidMount() {
      const token = window.localStorage.getItem('userToken')
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      let users = await callBackend({ method: 'GET', url: 'users/all', headers: headers }) || [];
      if (users.error && users.message.includes("status code 403")) {
        users = [];
        this.setState({showAlert: true})
      } else if (users.error) {
        alert("Ajaj, stala se chyba");
        console.log(users)
      }


      let organizations = await callBackend({ method: 'GET', url: 'organizations/all', headers: headers }) || [];
      if (organizations.error && organizations.message.includes("status code 403")) {
        organizations = [];
        this.setState({showAlert: true})
      } else if (organizations.error) {
        alert("Ajaj, stala se chyba");
        console.log(organizations)
      }
      const filteredUsers = users?.filter((x)=>x.latitude && x.longitude).map((x)=>{return {type: 'user', ...x }}) || [];
      const filteredOrgs = organizations?.filter((x)=>x.latitude && x.longitude).map((x)=>{return {type: 'org', ...x }}) || [];

      const mapUsersAllInfoCards = [...filteredUsers,...filteredOrgs]
      .map((user, index) => <UsersCards
        info={user}
        key={index}
      />)
      || null;


      this.setState({ mapUsersAllInfoCards: mapUsersAllInfoCards })
      this.setState({ users: filteredUsers });
      this.setState({ organizations: filteredOrgs });
      this.setState({ data: [...filteredUsers,...filteredOrgs] });
      this.setState({ usersChecked: true });
      this.setState({ orgsChecked: true });
    }

  
  handleMapChange (bounds) 
  {
    const users = this.state.users;
    const orgs = this.state.organizations;
    const usersChecked = this.state.usersChecked;
    const orgsChecked = this.state.orgsChecked;
    const data = [];
    if(usersChecked)
    {
      data.push(...users.filter((x)=>isInBounds(x,bounds)))
    }
    if(orgsChecked)
    {
      data.push(...orgs.filter((x)=>isInBounds(x,bounds)))
    }
    this.setState({mapUsersAllInfoCards:data.map((user, index) => <UsersCards
          info={user}
          key={index}
        />)
        || null});
  }

  handleChangeMapFilter = (e) => {
    let usersChecked = false;
    let orgsChecked = false;
    const data = [];
    if(e.target.id=='usersCheckbox')
      {
        this.setState({ usersChecked: e.target.checked });
        usersChecked = e.target.checked;
        orgsChecked = this.state.orgsChecked;
      }
    else
      {
        this.setState({ orgsChecked: e.target.checked });
        orgsChecked = e.target.checked;
        usersChecked = this.state.usersChecked;
      }
     
    if(usersChecked)
      {
        data.push(...this.state.users);
      }
    if(orgsChecked)
      {
        data.push(...this.state.organizations);
      }

    this.setState({data});
    
  }

  render() {
    const { t } = this.props;
    this.props.user.latitude ? window.localStorage.setItem('lat', this.props.user.latitude ) : null;
    this.props.user.longitude ? window.localStorage.setItem('long', this.props.user.longitude) : null;
    const filteredUsers = this.state.data;
    const mapUsersAllInfoMarker = filteredUsers
      .map((user, index) => <Marker
        lat={parseFloat(user.latitude)}
        lng={parseFloat(user.longitude)}
        info={user}
        key={index}
        />)
      || null;

    const mapUsersAllInfoCards = this.state.mapUsersAllInfoCards;

    // set Varnsdorf as default if user does not have address
    const latitude = parseFloat(window.localStorage.getItem('lat')) || 50.91134659999999;
    const longitude = parseFloat(window.localStorage.getItem('long')) || 14.6199675;

    return (
      // Important! Always set the container height explicitly
      <StyledContainer>
        {this.state.showAlert &&  
          <Alert variant='danger'>
             <Alert.Heading>{t('map.hidden.title')}</Alert.Heading>
             <hr />
             <p>{t('map.hidden.text')}</p>
             {this.state.user.isOrg 
              ? <Link to="/profil-organizace">{t('map.hidden.link')}</Link>
              : <Link to="/profil-uzivatel">{t('map.hidden.link')}</Link>}
          </Alert>}
        <Row className='first-row'>
          <Col className="col-2">
            <Form>
              <Form.Label className='bold'>{t('map.filter')}</Form.Label>
              <Form.Check 
                name="users"
                type="checkbox"
                id="usersCheckbox"
                defaultChecked={true}
                onChange={this.handleChangeMapFilter.bind(this)}
                label={t('map.filter.person')}
              />
              <Form.Check 
                name="orgs"
                type="checkbox"
                id="orgsCheckbox"
                defaultChecked={true}
                onChange={this.handleChangeMapFilter.bind(this)}
                label={t('map.filter.organization')}
              />
            </Form>
          </Col>
          <Col className="col-10" style={{ height: '75vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: googleApiKey }}
              defaultCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
              defaultZoom={this.props.zoom ?? 11}
              onChange={({ bounds }) => {
                this.handleMapChange([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat
                ]);
              }}
            >         
              {mapUsersAllInfoMarker.length ? mapUsersAllInfoMarker : null}          
            </GoogleMapReact>
          </Col>
        </Row>
        <Row className="users-cards">
          {mapUsersAllInfoCards}
        </Row>
      </StyledContainer>
    );
  }
}

const MapTranslateWrapper = (props) => {
  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))
  return (
    <SimpleMap {...props} t={t}/>
  )
}

export default view(MapTranslateWrapper);