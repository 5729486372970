import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import appStore from "../../appStore";
import callBackend from "../../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faBuilding, 
  faFile, 
  faHeart, 
  faLocationDot, 
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import { useTranslate } from "../../translate";

const StyledFontAwesomIcon = styled(FontAwesomeIcon)`
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover {
    color: #34659D;
  }
`;

const StyledCard = styled(Card)`
  .row {
    margin-bottom: 12px;

    svg{
      transform: translate(0, 0);
  }
}
`;

const StyledModal = styled(Modal)`

  .modal-content {
    background: #6EB6BA;
  }

  .modal-header {
    color: whitesmoke;
    border-bottom: #6EB6BA;
    font-size: large;
  }

  .white-text {
    color: whitesmoke;
  }

  .profile-photo {
    border-radius: 15%;
    max-height: 150px;
    max-width: 100px;
    margin-right: 20px;
  }

  .contact-button {
    background-color: #E3A51A;
    color: black;
    border-radius: 15px;
    margin: 0 0 15px 30px;
    border-color: black;

    &:hover {
      background-color: #e5ae30;
      border-color: black;
    }
  }

  .margin-top {
    margin: 15px 0 0 0;
  }
`;

export function Marker({ info }) {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [contactedUser, setContactedUser] = useState(info)

  const [show, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const { t } = useTranslate();

  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Zpráva je povinná")
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });


  async function contact(data) {
    let user = appStore.user;
    const token = window.localStorage.getItem('userToken')
    const message = data.message;
    const body = { userId: user.id, contactedUserId: contactedUser.id, message: message }
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
  
    if (contactedUser.type === 'user') {
      const tryContactUser = await callBackend({ method: 'POST', url: 'users/contact', headers: headers, payload: body });
      if (!tryContactUser.error) {
        alert(`${t('map.contact.user.success')}`)
      }
      else {
        alert('Nepodařilo se kontaktovat uživatele.')
        console.log(tryContactUser)
      }
    } else {
      const tryContactUser = await callBackend({ method: 'POST', url: 'organizations/contact', headers: headers, payload: body });
      if (!tryContactUser.error) {
        alert(`${t('map.contact.user.success')}`)
      }
      else {
        alert('Nepodařilo se kontaktovat uživatele.')
        console.log(tryContactUser)
      }
    }
  
    handleModalClose();
    handleClose();
    }

  async function upVote(e) {
    e.stopPropagation()
    const user = appStore.user
    if (user.isOrg || (!user.isOrg && contactedUser.isOrg)) {
      alert(`${t('map.upvote.user.error.organization')}`)
      return
    }
    let contactedUserId = info.id;
    const token = window.localStorage.getItem('userToken')
    const body = { id: contactedUserId }
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
  
    const tryUpVoteUser = await callBackend({ method: 'POST', url: 'users/upvote', headers: headers, payload: body });
    if (!tryUpVoteUser.error) {
      contactedUser.upVotes +=1
      setContactedUser(contactedUser => ({
        ...contactedUser,
        ...contactedUser.upvotes += 1
      }));
      alert(`${t('map.upvote.user.success')}`)
    }
    else if (tryUpVoteUser.error && tryUpVoteUser.message.includes("status code 409")) {
      alert(`${t('map.upvote.user.conflict')}`)
    }
    else {
      alert(`${t('map.upvote.user.error')}`)
      console.log(tryUpVoteUser)
    }
  }

  let isUser = false
  let isOrg = false
  if (info.type === 'user') {
    isUser = true;
  } else {
    isOrg = true
  }

  return (
    <>
    {isUser && <StyledFontAwesomIcon
        // {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
        icon={faUser}
        size="2xl"
        color="#274C77"
      />}
    {isOrg && <StyledFontAwesomIcon
        // {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
        icon={faBuilding}
        size="2xl"
        color="#274C77"
      />}      
      <StyledModal show={isOpen} onHide={handleClose}>
        <StyledModal.Header closeButton>
          <StyledModal.Title>{info.firstName} {info.lastName}</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
          <Row>
              <Col md={8}>
                <div className="mb-3"> 
                  <FontAwesomeIcon 
                    icon={faLocationDot} 
                    color="whitesmoke"
                    className="mr-3"/>
                  {info.city}
                </div>
                <div className="mb-3"> 
                  <FontAwesomeIcon 
                    icon={faFile} 
                    color="whitesmoke"
                    className="mr-3"/>
                  {info.info || '...'}
                </div>
                {info.upvotes > 0 ? 
                  <div className="mb-3"> 
                    <FontAwesomeIcon 
                      icon={faHeart} 
                      color="#f2a196"
                      className="mr-3"
                      onClick={upVote}/>
                    {info.upvotes}
                  </div>
                : <div className="mb-3"> 
                <FontAwesomeIcon 
                  icon={faHeart} 
                  color="whitesmoke"
                  className="mr-3"
                  onClick={upVote}/>
              </div> }
              </Col>
              <Col md={4} className="pr-3">
              {info.profilePictureUrl ? 
                <img src={info.profilePictureUrl} className="profile-photo"  alt="profilePhoto" />
                : null
              }
              </Col>
            </Row>
            <Row>
              <Button className="contact-button" onClick={handleModalShow}>{t('map.contact.user')}</Button>
              </Row>
        </StyledModal.Body>
      </StyledModal>

      <StyledModal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('map.modal.contact.user.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('map.modal.contact.user.body')}
        <Form onSubmit={handleSubmit(contact)} className="modalForm">
            <Form.Row className="alignLeft">
              <Form.Control
                name="message"
                ref={register}
                as="textarea"
                placeholder={t('map.modal.contact.user.message.placeholder')}
                rows={4}
                className={`form-control ${errors.message ? "is-invalid" : ""}`}>
              </Form.Control>
              <div className="invalid-feedback">{errors.message?.message}</div>
            </Form.Row>
            <Form.Group controlId="resetSubmit" className="alignRight">
                <Button variant="light" type="submit" className="submit-button contact-button margin-top">
                  {t('map.contact.user')}
                </Button>
              </Form.Group>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
}

export function UsersCards({ info }) {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [contactedUser, setContactedUser] = useState(info)

  const [show, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const { t } = useTranslate();

  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Zpráva je povinná")
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function contact(data) {
    let user = appStore.user;
    const token = window.localStorage.getItem('userToken')
    const message = data.message;
    const body = { userId: user.id, contactedUserId: contactedUser.id, message: message }
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
  
    if (contactedUser.type === 'user') {
      const tryContactUser = await callBackend({ method: 'POST', url: 'users/contact', headers: headers, payload: body });
      if (!tryContactUser.error) {
        alert(`${t('map.contact.user.success')}`)
      }
      else {
        alert('Nepodařilo se kontaktovat uživatele.')
        console.log(tryContactUser)
      }
    } else {
      const tryContactUser = await callBackend({ method: 'POST', url: 'organizations/contact', headers: headers, payload: body });
      if (!tryContactUser.error) {
        alert(`${t('map.contact.user.success')}`)
      }
      else {
        alert('Nepodařilo se kontaktovat uživatele.')
        console.log(tryContactUser)
      }
    }

    handleModalClose();
    handleClose();
  }

  async function upVote(e) {
    e.stopPropagation()
    const user = appStore.user;
    if (user.isOrg || (!user.isOrg && contactedUser.isOrg)) {
      alert(`${t('map.upvote.user.error.organization')}`)
      return
    }
    const token = window.localStorage.getItem('userToken')
    const body = { id: info.id }
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
  
    const tryUpVoteUser = await callBackend({ method: 'POST', url: 'users/upvote', headers: headers, payload: body });
    if (!tryUpVoteUser.error) {
      setContactedUser(contactedUser => ({
        ...contactedUser,
        ...contactedUser.upvotes += 1
      }));
      alert(`${t('map.upvote.user.success')}`)
    }
    else if (tryUpVoteUser.error && tryUpVoteUser.message.includes("status code 409")) {
      alert(`${t('map.upvote.user.conflict')}`)
    }
    else {
      alert(`${t('map.upvote.user.error')}`)
      console.log(tryUpVoteUser)
  }
}

  let isUser = false
  let isOrg = false
  if (info.type === 'user') {
    isUser = true;
  } else {
    isOrg = true
  }

  return (
    <>
    {isUser && <StyledCard onClick={() => setOpen((prev) => !prev)}>
          <StyledCard.Header as="h5">{info.firstName} {info.lastName}</StyledCard.Header>
          <StyledCard.Body>
            <StyledCard.Text>
              <Row>
                <Col md={1}>
                  <StyledFontAwesomIcon
                    icon={faUser}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{t('map.card.person')}</Col>
              </Row>
              <Row>
                <Col md={1}>
                  <StyledFontAwesomIcon
                    icon={faLocationDot}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{info.city || '...'}</Col>
              </Row>
              <Row>
                <Col md={1}>
                  <StyledFontAwesomIcon
                    icon={faFile}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{info.info || '...'}</Col>
              </Row>
              {info.upvotes > 0 ? 
                <Row>
                  <Col md={1}>
                    <StyledFontAwesomIcon
                      icon={faHeart}
                      size="xl"
                      color="#f2a196"
                      onClick={upVote}
                    /> 
                  </Col>
                  <Col>{info.upvotes}</Col>
                </Row>
              : <Row>
                <Col md={1}>
                  <StyledFontAwesomIcon
                    icon={faHeart}
                    size="xl"
                    color="#274C77"
                    onClick={upVote}
                  /> 
                </Col>
              </Row> }
            </StyledCard.Text>
          </StyledCard.Body>
        </StyledCard>}
    {isOrg &&  <StyledCard  onClick={() => setOpen((prev) => !prev)}>
          <StyledCard.Header as="h5">{info.title}</StyledCard.Header>
          <StyledCard.Body>
            <StyledCard.Text>
            <Row>
                <Col md={1}>
                  <StyledFontAwesomIcon
                    icon={faBuilding}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{t('map.card.organization')}</Col>
              </Row>
              <Row>
                <Col md={1} className="card-icon">
                  <StyledFontAwesomIcon
                    icon={faLocationDot}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{info.city || '...'}</Col>
              </Row>
              <Row>
                <Col md={1} className="card-icon">
                  <StyledFontAwesomIcon
                    icon={faFile}
                    size="xl"
                    color="#274C77"
                  /> 
                </Col>
                <Col>{info.info || '...'}</Col>
              </Row>
            </StyledCard.Text>
          </StyledCard.Body>
        </StyledCard>}      
      <StyledModal show={isOpen} onHide={handleClose}>
        <StyledModal.Header closeButton>
          <StyledModal.Title>{info.firstName} {info.lastName}</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
        <Row>
              <Col md={8}>
                <div className="mb-3"> 
                  <FontAwesomeIcon 
                    icon={faLocationDot} 
                    color="whitesmoke"
                    className="mr-3"/>
                  {info.city}
                </div>
                <div> 
                  <FontAwesomeIcon 
                    icon={faFile} 
                    color="whitesmoke"
                    className="mr-3"/>
                  {info.info}
                </div>
              </Col>
              <Col md={4} className="pr-3">
                <img src={info.profilePictureUrl} className="profile-photo"  alt="profilePhoto" />
              </Col>
            </Row>
            <Row>
              <Button className="contact-button" onClick={handleModalShow}>{t('map.contact.user')}</Button>
              </Row>
        </StyledModal.Body>
      </StyledModal>

      <StyledModal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('map.modal.contact.user.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('map.modal.contact.user.body')}
        <Form onSubmit={handleSubmit(contact)} className="modalForm">
            <Form.Row className="alignLeft">
              <Form.Control
                name="message"
                ref={register}
                as="textarea"
                placeholder={t('map.modal.contact.user.message.placeholder')}
                rows={4}
                className={`form-control ${errors.message ? "is-invalid" : ""}`}>
              </Form.Control>
              <div className="invalid-feedback">{errors.message?.message}</div>
            </Form.Row>
            <Form.Group controlId="resetSubmit" className="alignRight">
                <Button variant="light" type="submit" className="submit-button contact-button margin-top">
                  {t('map.contact.user')}
                </Button>
              </Form.Group>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
}
