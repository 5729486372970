import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker, UsersCards} from "../components/layout/Marker";
import { Container, Row, Col } from "react-bootstrap";
import callBackend from "../helpers";
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';


const StyledContainer = styled(Container)`
  .form-check {
    font-size: 12px;
  }
  .form-label {
    font-size: 14px;
    font-weight: bold;
  }
  .first-row {
    margin-top: 10px;
  }
  .users-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
    gap: 10px;
  }
  .card {
    background-color: #E7ECEF;
    border-radius: 15px;
    width: calc((100% / 3) - 30px);

    &:hover {
      background-color: #C8D4DB;
    }
  }
  .card-header {
    font-size: medium;
    color: #6EB6BA;
    font-weight: bold;
  }
  .card-text {
    font-size: small;
    text-align: left;

    b {
      color: #6EB6BA;
    }
  }
`;

const isInBounds = (marker, bounds) =>
  {
    const latitude = parseFloat(marker.latitude);
    const longitude = parseFloat(marker.longitude);
    return longitude>bounds[0] && longitude<bounds[2]
          && latitude>bounds[1] && latitude<bounds[3];
  }

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

function MapNew() {
  const [data, setData] = useState([]); 
  const user = appStore.user;
  const token = window.localStorage.getItem('userToken')

  const [users, setUsers] = useState([]); 
  const [organizations, setOrganizations] = useState([]);
  const [usersChecked, setUsersChecked] = useState(true); 
  const [orgsChecked, setOrgsChecked] = useState(true); 

  const [zoom, setZoom] = useState(); 
  const [bounds, setBounds] = useState([]); 

  const [mapUsersAllInfoCards, setMapUsersAllInfoCards] = useState(null); 
  const [mapUsersAllInfoMarker, setMapUsersAllInfoMarker] = useState(null); 

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  async function fetchUsers() {
    let users = await callBackend({ method: 'GET', url: 'users/all', headers: headers });
    if (users.error && users.message.includes("status code 403")) {
      alert("Abyste mohli vidět na mapě vidět další uživatele, je potřeba vyplnit celý profil");
      users = [];
    } else if (users.error) {
      alert("Ajaj, stala se chyba");
      console.log(users)
    }
    const filteredUsers = users?.filter((x)=>x.latitude && x.longitude).map((x)=>{return {type: 'user', ...x }}) || [];
    setUsers(filteredUsers)
  }

  async function fetchOrganizations() {
    let organizations = await callBackend({ method: 'GET', url: 'organizations/all', headers: headers });
    if (organizations.error) {
      organizations = [];
    }
    const filteredOrgs = organizations?.filter((x)=>x.latitude && x.longitude).map((x)=>{return {type: 'org', ...x }}) || [];
    setOrganizations(filteredOrgs)
  }

  useEffect(() => {
    fetchUsers().catch(console.error);
    fetchOrganizations().catch(console.error);

    setData([...users, ...organizations])

    let mapUsersAllInfoCardsUpdate = null
    let mapUsersAllInfoMarkerUpdate = null

    if (data && data.length) {
      mapUsersAllInfoCardsUpdate = data
      .map((user, index) => <UsersCards
        info={user}
        key={index}
      />)  
    
      mapUsersAllInfoMarkerUpdate = data
      .map((user, index) => <Marker
        lat={parseFloat(user.latitude)}
        lng={parseFloat(user.longitude)}
        info={user}
        key={index}
        />)
    }
    setMapUsersAllInfoCards(mapUsersAllInfoCardsUpdate)
    setMapUsersAllInfoMarker(mapUsersAllInfoMarkerUpdate)
  
  }, [])
  
  useEffect(() => {
    if (data && data.length) {
      let mapUsersAllInfoMarker = data
        .map((user, index) => <Marker
          lat={parseFloat(user.latitude)}
          lng={parseFloat(user.longitude)}
          info={user}
          key={index}
          />)
        setMapUsersAllInfoMarker(mapUsersAllInfoMarker)
      }
  }, [])

  const handleMapChange = () => 
  {
    const data = [];
    if(usersChecked)
    {
      data.push(...users.filter((x)=>isInBounds(x,bounds)))
    }
    if(orgsChecked)
    {
      data.push(...organizations.filter((x)=>isInBounds(x,bounds)))
    }

    const mapUsersAllInfoCards = data.map((user, index) => <UsersCards
        info={user}
        key={index}
      />)
      || null;

    setMapUsersAllInfoCards(mapUsersAllInfoCards)
  }

  const handleChangeMapFilter = (e) => {
    setUsersChecked(false);
    setOrgsChecked(false);
    if(e.target.id=='usersCheckbox')
    {
      setUsersChecked(e.target.checked)
      setOrgsChecked(orgsChecked)
    }
    else
    {
      setOrgsChecked(e.target.checked)
      setUsersChecked(usersChecked)
    }
     
    const data = usersChecked ? users : [];
    if(orgsChecked)
    {
      data.push(...organizations);
    }

    setData(data)
  }

    // set Varnsdorf as default if user does not have address
    const latitude = user.latitude || 50.91134659999999;
    const longitude = user.longitude || 14.6199675;

    return (
      // Important! Always set the container height explicitly
      <StyledContainer>
        <Row className='first-row'>
          <Col className="col-2">
            <Form>
              <Form.Label className='bold'>{t('map.filter')}</Form.Label>
              <Form.Check 
                name="users"
                type="checkbox"
                id="usersCheckbox"
                defaultChecked={true}
                onChange={handleChangeMapFilter}
                label={t('map.filter.person')}
              />
              <Form.Check 
                name="orgs"
                type="checkbox"
                id="orgsCheckbox"
                defaultChecked={true}
                onChange={handleChangeMapFilter}
                label={t('map.filter.organization')}
              />
            </Form>
          </Col>
          <Col className="col-10" style={{ height: '75vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: googleApiKey }}
              defaultCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
              defaultZoom={zoom ?? 11}
              onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat
                ]);
                handleMapChange();
              }}
            >         
              {mapUsersAllInfoMarker?.length ? mapUsersAllInfoMarker : null}          
            </GoogleMapReact>
          </Col>
        </Row>
        <Row className="users-cards">
          {mapUsersAllInfoCards}
        </Row>
      </StyledContainer>
    );
  }

export default view(MapNew);


