import React from "react";
import { view } from "@risingstack/react-easy-state";
import { useHistory, Link } from "react-router-dom";
import appStore from "../appStore";
import { Container, Row, Col, Button } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import "../components/layout/layout.css";
import styled from 'styled-components';
import callBackend from "../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faHeart } from '@fortawesome/free-solid-svg-icons'
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledContainer = styled(Container)`

  font-size: 1rem;

  .profile-photo {
    width: 200px;
    height: 200px;
    display: block;
    margin: 45px auto 45px auto;
  }

  .display-center {
    margin-left: auto;
    margin-right: auto;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA;
    color: white;
  }

  button {
    border-radius: 15px;
  }

  .reset-password {
    display: block;
    margin: 15px auto 15px auto;
    background-color: #C4C4C4;
    color: black;
    border-color: #C4C4C4;
  }

  .create-event {
    display: block;
    margin: 15px auto 15px auto;
    background-color: #E3A51A;
    color: black;
    border-color: #E3A51A;
  }

  .contact-info {
    background-color: #E3A51A;
    padding: 30px;
    border-radius: 15px;
    color: black;
    margin: 30px auto 30px auto;
  }

  .contact-info .align-right {
    display: flex;
    flex-direction: row-reverse;
  }

  input {
    border-radius: 15px;
  }

  .heart-row {
    display: flex;
    justify-content: center;
  }
`;

function ProfileOrganization() {
  const history = useHistory();
  let user = appStore.user;

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))
  
  // eslint-disable-next-line no-inner-declarations
  function logout() {
    appStore.user = {};
    window.localStorage.clear();
    history.push({ pathname: "/login" });
  }

  async function handleResetPassword() {
    const payload = { email: user.email }
    const result = await callBackend({ url: 'users/resetPassword', payload: payload })
    if (result.error) {
      alert("Nepodařilo se");
      console.log(result)
    } else {
      alert("Poslali jsme Vám email, Dozvíte se v něm, jak znovu nastavit Vaše heslo.");
      logout();
    }
  }
  
  return (
    <StyledContainer>
        <Row>
          <Image className="profile-photo"
            src={user.profilePictureUrl || 'building_avatar.png'} 
            roundedCircle={true} 
            alt="profilePhoto">
          </Image>
        </Row>
        <Row>
        <Form className="display-center">
          <Form.Group className="mb-3">
            <Form.Label> {t('profile.organization.title')}</Form.Label>
            <Form.Control 
              placeholder= {user.title || t('profile.organization.title.placeholder') }
              disabled
            />
          </Form.Group>
            <Form.Label>{t('profile.organization.representative')}</Form.Label>
            <Row className="mb-3">
              <Col>
                <Form.Control 
                  placeholder= {user.firstName || t('profile.name')}
                  disabled
                />
              </Col>
              <Col>
                <Form.Control 
                  placeholder={user.lastName || t('profile.surname')}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Button variant="secondary" size="sm" className="reset-password" onClick={handleResetPassword}>
                {t('profile.change.password')}
              </Button>
            </Row>
          </Form>
        </Row>
        <Row className="contact-info">
          <Col>
            {t('profile.contact.info')}
          </Col>
          <Col className="align-right">
            <Link to="/profil-organizace/editace">
              <FontAwesomeIcon icon={faPencil} />
            </Link>
          </Col>
        </Row>
        <Row className="heart-row">
            {user.upvotes > 0 
            ? <div> 
                <FontAwesomeIcon 
                  icon={faHeart} 
                  color="#f2a196"
                  className="mr-3"/>
                {user.upvotes}
              </div>
            : <div> 
            <FontAwesomeIcon 
              icon={faHeart} 
              color="whitesmoke"
              className="mr-3"/>
            </div> }
          </Row>
    </StyledContainer>
    );
  }

export default view(ProfileOrganization);
