import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory, Link } from "react-router-dom";
import appStore from "../../appStore";
import callBackend from "../../helpers"
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import styled from 'styled-components';
import { FormCheck } from "react-bootstrap";
import StyledCard from "../../components/layout/styledCard";
import { useTranslate } from "../../translate";
import  LanguageSwitcher from "../../components/layout/languageSwitcher";
import { setCurrentLanguage } from '../../translate/Translate';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const StyledCardRegistration = styled(StyledCard)`
  .logo_languageSwitcher {
    width: 100%;
    margin-top: 10px
  }

  .logos {
    background-color: white;
    display: flex;
    justify-content: center;
  }

  .registrationRadioField {
    background-color: white;
    text-align: left;
    border-radius: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .formCheck {
    padding: 1rem 0 0 2rem;
  }

  .acceptTerms {
    padding-left: 1.8rem;
  }

`;

export default function UserRegistrationOneStepOnly() {
  const [state, setState] = useState({userChecked:true,
  gdprChecked:false,
  termsChecked:false});
  const {userChecked, gdprChecked, termsChecked} = state;

  
  function radioClicked (data) {
    if(data.target.id=='userRegistrationRadio')
    {
      setState({...state,userChecked:true});
    }
    else
    {
      setState({...state,userChecked:false});
    }
  }

  function termsClicked (data) {
    if(data.target.checked)
    {
      setState({...state, termsChecked:true});
    }  
    else
    {
      setState({...state, termsChecked:false});
    }  
  }
  function gdprClicked (data) {
    if(data.target.checked)
    {
      setState({...state, gdprChecked:true});
    }
    else
    {
      setState({...state, gdprChecked:false});
    }
  }


  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email je povinný").email("Email není platný"),
    password: Yup.string()
      .min(6, "Heslo musí mít alespoň 6 znaků")
      .required("Heslo je povinné"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Hesla se musí shodovat")
      .required("Potvrzení hesla je povinné"),
    acceptTermsServices: Yup.bool().oneOf([true], "Souhlas s podmínkami služeb je nutný"),
    acceptTermsGDPR: Yup.bool().oneOf([true], "Souhlas se zpracováním osobních údajů je nutný")
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const history = useHistory();

  async function onSubmit(data) {
    appStore.updateContact(data);
    const result = data.userRegistrationRadioGroup === 'user'
      ? await callBackend({ url: 'users/registrationUser', payload: data })
      : await callBackend({ url: 'organizations/registrationOrganization', payload: data });

    if (result.error) {
      alert("Ajaj, stala se chyba");
      console.log(result)
    } else {
      alert(
        `${t('registration.success.text')}`
      );
      history.push({ pathname: "/login" });
    }
  }

  async function onSubmitGoogle(data) {
    if(!checkGDPRandTerms())
    {
      return;
    }
        
    console.log('data '+JSON.stringify(data));
    const loggedUser = userChecked?await callBackend({ url: 'users/registrationGoogle', payload: {access_token:data.access_token }}):
    await callBackend({ url: 'organizations/registrationGoogle', payload: {access_token:data.access_token }});
    if (!loggedUser.error) {
      loggedUser.isOrg = !userChecked;
      appStore.addContact(loggedUser);
      let myStorage = window.localStorage;
      myStorage.setItem('userToken', appStore.user.token);
      myStorage.setItem('user', JSON.stringify(appStore.user));
      history.push({ pathname: "/home" });
      return;
    } 
    else {
        alert("error");
        history.push({ pathname: "/registrace" });
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await onSubmitGoogle(tokenResponse);
    },
    onError: errorResponse =>
    {
      alert('Při přihlášení se stala chyba.');
      console.log(errorResponse);
    }
  });

  function checkGDPRandTerms()
  {
    if(!termsChecked)
    {
      alert('Souhlas s podmínkami služeb je nutný');
      return false;
    }
    if(!gdprChecked)
    {
      alert('Souhlas se zpracováním osobních údajů je nutný');
      return false;
    }
    return true;

  }

  async function onSubmitFacebook (data)  {
    if(!checkGDPRandTerms())
    {
      return;
    }
    const loggedUser = userChecked? await callBackend({ url: 'users/registrationFacebook', payload: {accessToken:data.accessToken }}):
                                    await callBackend({ url: 'organizations/registrationFacebook', payload: {accessToken:data.accessToken }})
    if (!loggedUser.error) {
      loggedUser.isOrg = !userChecked;
      appStore.addContact(loggedUser);
      let myStorage = window.localStorage;
      myStorage.setItem('userToken', appStore.user.token);
      myStorage.setItem('user', JSON.stringify(appStore.user));
      history.push({ pathname: "/home" });
      return;
    } 
    else {
        alert("Uživatel nenalezen");
        history.push({ pathname: "/login" });
    }
  }

  return (
    <Container>
      <StyledCardRegistration>
        <div className="logo_languageSwitcher">
          <div className='logos'>
            <img src='EU_logo.jpg' width="300" height="64" alt="Evropský fond pro regionální rozvoj"/>
            <img src='SNCZ_logo.jpg' width="90" height="64" alt="Sousede Nachbarn"/>
          </div>
          <LanguageSwitcher></LanguageSwitcher>
        </div>
        <Card.Body>
          <Card.Title className="title">{t('registration.maintext')}</Card.Title>
          <Card.Subtitle className="subtitle text-muted">{t('registration.subtext')}</Card.Subtitle>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <Form.Group controlId="registrationEmail">
                <Form.Row>
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder={t('registration.username.placeholder')}
                    ref={register}
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}>
                  </Form.Control>
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="registrationPassword">
                <Form.Row>
                  <Form.Control
                      name="password"
                      type="password"
                      placeholder={t('registration.password.placeholder')}
                      ref={register}
                      className={`form-control ${errors.password ? "is-invalid" : ""
                        }`}>
                  </Form.Control>
                  <div className="invalid-feedback">{errors.password?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="registrationPassword">
                <Form.Row>
                  <Form.Control
                      name="confirmPassword"
                      type="password"
                      placeholder={t('registration.password.control.placeholder')}
                      ref={register}
                      className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
                    }`}>
                  </Form.Control>
                  <div className="invalid-feedback"> {errors.confirmPassword?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row className="registrationRadioField">
                  <FormCheck className="formCheck">
                    <FormCheck.Input
                      onClick={radioClicked} 
                      name="userRegistrationRadioGroup"
                      type="radio"
                      ref={register}
                      value='user'
                      id="userRegistrationRadio"
                      defaultChecked
                      className={`form-check-input ${errors.userRegistrationRadio ? "is-invalid" : ""
                        }`}
                      />
                    <FormCheck.Label className="radioInputText">
                    <b>{t('registration.as.person')}</b>
                    <br />
                      {t('registration.as.person.subtext')}
                    </FormCheck.Label>
                    <div className="invalid-feedback"> {errors.userRegistrationRadio?.message}</div>
                  </FormCheck>
                </Form.Row>
                <Form.Row className="registrationRadioField">
                  <FormCheck className="formCheck">
                      <FormCheck.Input 
                        onClick={radioClicked} 
                        name="userRegistrationRadioGroup"
                        type="radio"
                        ref={register}
                        value='organization'
                        id="organizationRegistrationRadio"
                        className={`form-check-input ${errors.organizationRegistrationRadio ? "is-invalid" : ""
                          }`}
                        />
                      <FormCheck.Label className="radioInputText">
                      <b>{t('registration.as.organization')}</b>
                      <br />
                        {t('registration.as.organization.subtext')}
                      </FormCheck.Label>
                      <div className="invalid-feedback"> {errors.organizationRegistrationRadio?.message}</div>
                    </FormCheck>
                  </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row className="acceptTerms">
                  <FormCheck.Input
                    onClick={termsClicked} 
                    name="acceptTermsServices"
                    type="checkbox"
                    ref={register}
                    id="acceptTermsServices"
                    className={`form-check-input ${errors.acceptTermsServices ? "is-invalid" : ""
                      }`}
                    />
                  <FormCheck.Label>
                      <Link to="/podminky-uzivani-sluzby" target="_blank" rel="noopener noreferrer">{t('registration.agreement.tos')}</Link>
                  </FormCheck.Label>
                  <div className="invalid-feedback"> {errors.acceptTermsServices?.message}</div>
                </Form.Row>
                <Form.Row className="acceptTerms">
                  <FormCheck.Input
                    onClick={gdprClicked} 
                    name="acceptTermsGDPR"
                    type="checkbox"
                    ref={register}
                    id="acceptTermsGDPR"
                    className={`form-check-input ${errors.acceptTermsGDPR ? "is-invalid" : ""
                      }`}
                    />
                  <FormCheck.Label>
                    <Link to="/podminky-vyuziti-osobnich-udaju" target="_blank" rel="noopener noreferrer">{t('registration.agreement.gdpr')}</Link>
                  </FormCheck.Label>
                  <div className="invalid-feedback"> {errors.acceptTermsGDPR?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="registrationSubmit">
                <Button variant="light" type="submit" className="submit-button">
                  {t('registration.registration')}
                </Button>
              </Form.Group>
              <Form.Group controlId="loginGoogleFacebook">
                <Button onClick={() => googleLogin()} className="google-button ml-3">
                  <img src="google_logo.svg" width="80" height="20"></img>
                </Button>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_ID}
                  fields="email"
                  scope="email"
                  callback={onSubmitFacebook}
                  icon="fa-facebook"
                  render={ (renderProps) => (
                    <Button 
                      onClick={renderProps.onClick}
                      className="facebook-button ml-3">
                        <img src="facebook_logo.svg" width="80" height="20"></img>
                      </Button>
                  )}
                   />
              </Form.Group>
              <Form.Group  controlId="registrationLoginRedirect">
                <Form.Text>
                  <Link to="/login">{t('registration.login')}</Link>
                </Form.Text>
              </Form.Group>
            </Form>
          </Card.Body>
      </StyledCardRegistration>
    </Container>
  );
}
