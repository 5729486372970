import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import appStore from '../appStore';
import moment from 'moment';
import { view } from "@risingstack/react-easy-state";
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faBuilding, 
  faClock, 
  faImage, 
  faLocationDot, 
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';
import { Link } from "react-router-dom";

const StyledContainerCarousel = styled(Container)`
    font-size: 14px;
    width: 100%;
    height: 100%;

    .container {
      width: 960px;
      height: 300px;
    }

    .carousel {
      width: 960px;
      height: 300px;
    }

    .carousel-inner {
      width: 960px;
      height: 300px;
    }

    .carousel-item {
      background-color: #E3A51A !important;
      border-radius: 15px;
      color: black;
      width: 100%;
      height: 100%;
    }

    .carousel-caption {
      position: static;
      padding: 24px 0 0px 0;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      color: black !important;
      margin-bottom: 8px;
      text-align: left !important;
    }

    a {
      color: #274C77;
      font-weight: 500;

      :hover {
        color: whitesmoke;
      }
    }

    .carousel-indicators .li .active {
      background-color: white !important;
    }

    .carousel-row {
      margin-left: 100px;
      margin-top: 50px;
    }

    img {
      max-width: 200px;
      max-height: 100px;
      margin-left: 0px;
    }

    .display-flex {
      display: flex;
      justify-content: flex-start;
    }

    .justify-content-center {
      justify-content: center;
    }
`;

const StyledContainerTable = styled(Container)`
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin-top: 60px;

    img {
      max-width: 100px;
      max-height: 100px;

    }

    .container {
      width: 960px;
      height: 500px;
    }

    .table-container {
      margin: 20px 0 20px 0;
      border-radius: 20px;
      max-height: 400px;
      padding: 0 10px 10px 10px;
      overflow: scroll;
    }

    .table {
      text-align: center;
    }

    .table tbody tr {
      :hover {
          background-color: #f8e6bf;
        }
    }

    .table td {
      vertical-align: middle;
    }

    .table thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #274C77;
      color: whitesmoke;
    }

    p {
      color: black !important;
      text-align: left;
    }

    a {
      color: #17a2b8;
      font-weight: 500;

      :hover {
        color: black;
      }
    }

    .get-volunteers {
      font-size: 14px;
      color: #17a2b8;

      :hover {
        color: black;
      }
    }
`;

function Home() {
  // const user = appStore.user;
  const token = window.localStorage.getItem('userToken')
  const user = JSON.parse(window.localStorage.getItem('user'))
  const history = useHistory();
  const [eventsArray, setEventsArray] = useState([]);
  const [userFutureEvents, setUserFutureEvents] = useState([]);
  const [userPastEvents, setUserPastEvents] = useState([]);
  const [orgFutureEvents, setOrgFutureEvents] = useState([]);
  const [orgPastEvents, setOrgPastEvents] = useState([]);
  const [index, setIndex] = useState(0);
  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  async function fetchNews() {
    const data = await callBackend({ method: 'GET', url: 'organizations/news', headers: headers });
    if (data.error) {
      console.log(data);
    } else {
      const now = moment().local();
      const futureNews = data.filter((x)=>moment(x.startDate).local()>now);
      futureNews.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setEventsArray(futureNews);
    }
  }

  async function userEvents() {
    const data = await callBackend({ method: 'GET', url: 'users/events', headers: headers });
    if (data.error) {
      // alert('Při načítání událostí se stala chyba.')
      console.log(data);
    } else {
      const now = moment().local();
      const userFutureEvents = data.filter((x)=>moment(x.startDate).local()>now);
      userFutureEvents?.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setUserFutureEvents(userFutureEvents);
      const userPastEvents = data.filter((x)=>moment(x.startDate).local()<now);
      userPastEvents?.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setUserPastEvents(userPastEvents);
    }
  }

  async function organizationsEvents() {
    const data = await callBackend({ method: 'GET', url: 'organizations/events', headers: headers });
    if (data.error) {
      // alert('Při načítání událostí se stala chyba.')
      console.log(data);
    } else {
      const now = moment().local();
      const orgFutureEvents = data.filter((x)=>moment(x.startDate).local()>now);
      orgFutureEvents.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setOrgFutureEvents(orgFutureEvents);
      const orgPastEvents = data.filter((x)=>moment(x.startDate).local()<now);
      orgPastEvents.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setOrgPastEvents(orgPastEvents);
    }
  }

  async function getVolunteersRedirect(eventPlanned) {
    history.push({ pathname: "/volunteers-per-event"})
    appStore.addEvent(eventPlanned)
  }

  useEffect(() => {
    fetchNews();
    if (!user.isOrg) {
      userEvents();
    } else {
      organizationsEvents()
    }
  }, [])


    const events = eventsArray;
    const carouselItems = events
      .map((eventPlanned, index) => <Carousel.Item key={index}>
               <Carousel.Caption>
                 <h2>{eventPlanned.name}</h2>
                 <Row className='carousel-row'>
                  <Col xs={7}>
                    <p>
                      <FontAwesomeIcon 
                        icon={faBuilding} 
                        color="whitesmoke"
                        className="mr-3"/>
                        {eventPlanned.title}
                    </p>
                    <p>
                      <FontAwesomeIcon 
                      icon={faLocationDot} 
                      color="whitesmoke"
                      className="mr-3"/>
                      {eventPlanned.city}
                    </p>
                    <p>
                      <FontAwesomeIcon 
                      icon={faClock} 
                      color="whitesmoke"
                      className="mr-3"/>
                      {moment(eventPlanned.startDate).local().format('DD/MM/YY HH:mm')} - {moment(eventPlanned.endDate).local().format('DD/MM/YY HH:mm')} 
                    </p>
                  </Col>
                  <Col xs={5} className='display-flex'>
                    <img src={eventPlanned.profilePictureUrl}/>
                  </Col>
                 </Row>
                 <Row className='justify-content-center'>
                  <a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')} </a>
                 </Row>
               </Carousel.Caption>
           </Carousel.Item>) || null;
    
    const userFutureEventsRow = userFutureEvents
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.title}</td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.helpTitle}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
    </tr>)
    || null;

    const userPastEventsRow = userPastEvents
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.title}</td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.helpTitle}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
    </tr>)
    || null;

    const orgFutureEventsRow = orgFutureEvents
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
        <td><Button variant="link" size="sm" className='get-volunteers' onClick={() => getVolunteersRedirect(eventPlanned)}>{t('home.event.helpers.list')}</Button></td>
    </tr>)
    || null;

    const orgPastEventsRow = orgPastEvents
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
    </tr>)
    || null;

    return (
      <>
      <StyledContainerCarousel>
      <h3>{t('home.news')}</h3>
        <Carousel activeIndex={index} onSelect={handleSelect} fade>
          {carouselItems}
        </Carousel>
      </StyledContainerCarousel>
      
      
      {!user.isOrg &&
      <StyledContainerTable>
        <Row>
          <Col>
          <h4>{t('events.registered')}</h4>
          {userFutureEvents.length 
            ? <div className='table-container'>
              <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th> 
                        <FontAwesomeIcon 
                          icon={faImage} 
                          color="whitesmoke"/>
                      </th>
                      <th>{t('home.organization.title')}</th>
                      <th>{t('event.create.name.placeholder')}</th>
                      <th>{t('home.event.city')}</th>
                      <th>{t('event.create.place.date.from')}</th>
                      <th>{t('event.create.place.date.to')}</th>
                      <th>{t('home.event.help.type')}</th>
                      <th>{t('home.event.help.info')}</th>
                      <th>{t('home.event.web')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userFutureEventsRow}
                  </tbody>
                </Table>
              </div>
            : <p className="ml-2">{t('home.user.no.future.events')} <Link to='/events'>{t('general.words.here')}.</Link></p>
          }
            </Col>
            <Col>
            <h4>{t('past.events')}</h4>
            {userPastEvents.length 
            ? <div className='table-container'>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <FontAwesomeIcon 
                          icon={faImage} 
                          color="whitesmoke"/>
                    </th>
                    <th>{t('home.organization.title')}</th>
                    <th>{t('event.create.name.placeholder')}</th>
                    <th>{t('home.event.city')}</th>
                    <th>{t('event.create.place.date.from')}</th>
                    <th>{t('event.create.place.date.to')}</th>
                    <th>{t('home.event.help.type')}</th>
                    <th>{t('home.event.help.info')}</th>
                    <th>{t('home.event.web')}</th>
                  </tr>
                </thead>
                <tbody>
                  {userPastEventsRow}
                </tbody>
              </Table>
            </div>
            : <p className="ml-2">{t('home.no.past.events')}</p>
          }
            </Col>
          </Row>
        </StyledContainerTable>
        }
        {user.isOrg &&
        <StyledContainerTable>
          <Row>
            <Col>
            <h4>{t('home.event.organization.future.events')}</h4>
            {orgFutureEvents.length 
              ?
              <div className='table-container'>
                <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>
                          <FontAwesomeIcon 
                          icon={faImage} 
                          color="whitesmoke"/>
                        </th>
                        <th>{t('event.create.name.placeholder')}</th>
                        <th>{t('home.event.city')}</th>
                        <th>{t('event.create.place.date.from')}</th>
                        <th>{t('event.create.place.date.to')}</th>
                        <th>{t('home.event.help.info')}</th>
                        <th>{t('home.event.web')}</th>
                        <th>{t('home.event.helpers.list')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgFutureEventsRow}
                    </tbody>
                  </Table>
                </div> 
               : <p className="ml-2">{t('home.organization.no.future.events')} <Link to='/create-event'>{t('general.words.here')}.</Link></p>
              }           
              </Col>
              <Col>
              <h4>{t('past.events')}</h4>
              {orgPastEvents.length 
            ? 
              <div className='table-container'>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>
                        <FontAwesomeIcon 
                          icon={faImage} 
                          color="whitesmoke"/>
                      </th>
                      <th>{t('event.create.name.placeholder')}</th>
                      <th>{t('home.event.city')}</th>
                      <th>{t('event.create.place.date.from')}</th>
                      <th>{t('event.create.place.date.to')}</th>
                      <th>{t('home.event.help.info')}</th>
                      <th>{t('home.event.web')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orgPastEventsRow}
                  </tbody>
                </Table>
              </div>
              : <p className="ml-2">{t('home.no.past.events')}</p>
            }
              </Col>
            </Row>
          </StyledContainerTable>
        }
      </>  
    );

}

export default view(Home);
