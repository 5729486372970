// NPM dependencies
import React from "react";

// Application dependencies
import {
  useTranslate,
  useTranslateDispatch,
  useTranslateState
} from "../../translate";
import "./style.css";
import styled from 'styled-components';

const StyledLanguageSwitcher = styled.div`
    display: flex;
    justify-content: flex-end;

    .language-flag {
      background-color: #E3A51A;
      border-color: #E3A51A;
      border: 0;
      img {
        margin-bottom: 3px;
        width: 20px;
        height: 20px;
      }
    }
`

export default function LanguageSwitcher() {
  const { language } = useTranslateState();
  const i18n = useTranslate();
  const { t, getLanguages } = i18n;
  const dispatch = useTranslateDispatch();
  const flags = require.context('../../../public/flags', true);
  const usedFlag = flags(`./${language}.png`).default;

  const items = getLanguages().map(key => {
    return (
      <button
        className="language-flag"
        key={key}
        onClick={() => {
          dispatch({ type: "CHANGE_LANGUAGE", language: key });
        }}
      >
        <img src={flags(`./${key}.png`).default}></img>
      </button>
    ) 
  });

  return (
    <StyledLanguageSwitcher>
      {items}
    </StyledLanguageSwitcher>
  );
}

//export default LanguageSwitcher;
