import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import callBackend from "../helpers";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import StyledCard from "../components/layout/styledCard";
import styled from 'styled-components';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import  LanguageSwitcher from "../components/layout/languageSwitcher";
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';


const StyledModal = styled(Modal)`
  .modal-content {
    text-align: left;
    border-radius: 15px !important;
  }

  .modal-title {
    color: #6EB6BA;

  }

  .modal-footer {
    display: inline;
  }

  .modalForm {
    display: flex;
    justify-content: space-between !important;
  }

  input {
    border-radius: 15px;
  }
`;

function Login() {

  const [show, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email je povinný").email("Email není platný"),
    password: Yup.string()
      .min(6, "Heslo musí mít alespoň 6 znaků")
      .required("Heslo je povinné"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const history = useHistory();

  async function onSubmit(data) {
    const tryLoginAsUser = await callBackend({ url: 'users/login', payload: data })
    if (!tryLoginAsUser.error) {
      const loggedUser = tryLoginAsUser;
      loggedUser.isOrg = false;
      appStore.addContact(loggedUser);
      let myStorage = window.localStorage;
      myStorage.setItem('userToken', appStore.user.token);
      myStorage.setItem('user', JSON.stringify(appStore.user));
      history.push({ pathname: "/home" });
      return
    } else {
      const tryLoginAsOrg = await callBackend({ url: 'organizations/login', payload: data })
      if (!tryLoginAsOrg.error) {
        const loggedUser = tryLoginAsOrg;
        loggedUser.isOrg = true;
        appStore.addContact(loggedUser);
        let myStorage = window.localStorage;
        myStorage.setItem('userToken', appStore.user.token);
        myStorage.setItem('user', JSON.stringify(appStore.user));
        history.push({ pathname: "/home" });
        return
      } 
      else {
        alert("Špatné uživatelské jméno či heslo");
        history.push({ pathname: "/login" });
      }
    }
    
  }

  async function onSubmitGoogle(data) {
    const tryLoginAsUser = await callBackend({ url: 'users/loginGoogle', payload: data })
    if (!tryLoginAsUser.error) {
      const loggedUser = tryLoginAsUser;
      loggedUser.isOrg = false;
      appStore.addContact(loggedUser);
      let myStorage = window.localStorage;
      myStorage.setItem('userToken', appStore.user.token);
      myStorage.setItem('user', JSON.stringify(appStore.user));
      history.push({ pathname: "/home" });
      return
    } else {
      const tryLoginAsOrg = await callBackend({ url: 'organizations/loginGoogle', payload: data })
      if (!tryLoginAsOrg.error) {
        const loggedUser = tryLoginAsOrg;
        loggedUser.isOrg = true;
        appStore.addContact(loggedUser);
        let myStorage = window.localStorage;
        myStorage.setItem('userToken', appStore.user.token);
        myStorage.setItem('user', JSON.stringify(appStore.user));
        history.push({ pathname: "/home" });
        return
      } 
      else {
        alert("Uživatel nenalezen");
        history.push({ pathname: "/login" });
      }
    }
  }

  async function onSubmitFacebook (data)  {
    const tryLoginAsUser = await callBackend({ url: 'users/loginFacebook', payload: {accessToken:data.accessToken }})
    if (!tryLoginAsUser.error) {
      const loggedUser = tryLoginAsUser;
      loggedUser.isOrg = false;
      appStore.addContact(loggedUser);
      let myStorage = window.localStorage;
      myStorage.setItem('userToken', appStore.user.token);
      myStorage.setItem('user', JSON.stringify(appStore.user));
      history.push({ pathname: "/home" });
      return;
    } else {
      const tryLoginAsOrg = await callBackend({ url: 'organizations/loginFacebook', payload:  {accessToken:data.accessToken } })
      if (!tryLoginAsOrg.error) {
        const loggedUser = tryLoginAsOrg;
        loggedUser.isOrg = true;
        appStore.addContact(loggedUser);
        let myStorage = window.localStorage;
        myStorage.setItem('userToken', appStore.user.token);
        myStorage.setItem('user', JSON.stringify(appStore.user));
        history.push({ pathname: "/home" });
        return;
      } 
      else {
        alert("Uživatel nenalezen");
        history.push({ pathname: "/login" });
      }
    }
  }

  async function handleResetPassword(data) {
    const payload = { email: data.email }
    const result = await callBackend({ url: 'users/resetPassword', payload: payload })
    if (result.error) {
      alert("Nepodařilo se");
      history.push({ pathname: "/login" });
    } else {
      // TODO: have nice alert instead of this one
      alert("Poslali jsme Vám email, Dozvíte se v něm, jak znovu nastavit Vaše heslo.");
      history.push({ pathname: "/login" });
    }
    handleModalClose();
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await onSubmitGoogle(tokenResponse);
    },
    onError: errorResponse =>
    {
      alert('Při přihlášení se stala chyba.');
      console.log(errorResponse);
    }
  });

  return (
    <Container>
      <StyledCard>
      <div className="logo_languageSwitcher">
          <div className='logos'>
            <img src='EU_logo.jpg' width="300" height="64" alt="Evropský fond pro regionální rozvoj"/>
            <img src='SNCZ_logo.jpg' width="90" height="64" alt="Sousede Nachbarn"/>
          </div>
          <LanguageSwitcher></LanguageSwitcher>
        </div>

        <Card.Body>
          <Card.Title className="title">{t('login.login.maintext')}</Card.Title>
          <Card.Subtitle className="subtitle text-muted">{t('login.login.subtext')}</Card.Subtitle>
            <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <Form.Group controlId="loginEmail">
                <Form.Row>
                  <Form.Control 
                    name="email"
                    type="text"
                    placeholder={t('login.email.placeholder')}
                    ref={register}
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}>
                  </Form.Control>
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="loginPassword">
                <Form.Row>
                  <Form.Control
                      name="password"
                      type="password"
                      placeholder={t('login.password.placeholder')}
                      ref={register}
                      className={`form-control ${errors.password ? "is-invalid" : ""
                        }`}>
                  </Form.Control>
                  <div className="invalid-feedback">{errors.password?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="loginResetPassword">
                <Button variant="link" onClick={handleModalShow}>
                  {t('login.forgotten.password')}
                </Button>
              </Form.Group>
              <Form.Group controlId="loginSubmit">
                <Button variant="light" type="submit" className="submit-button">
                  {t('login.login')}
                </Button>
                </Form.Group>
                <Form.Group controlId="loginThrough">
                  <p>
                  {t('login.through')}
                  </p>
              </Form.Group>
              <Form.Group controlId="loginGoogleFacebook">
                <Button onClick={() => googleLogin()} className="google-button ml-3">
                  <img src="google_logo.svg" width="80" height="20"></img>
                </Button>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_ID}
                  fields="email"
                  scope="email"
                  callback={onSubmitFacebook}
                  icon="fa-facebook"
                  render={ (renderProps) => (
                    <Button 
                      onClick={renderProps.onClick}
                      className="facebook-button ml-3">
                        <img src="facebook_logo.svg" width="80" height="20"></img>
                      </Button>
                  )}
                   />
              </Form.Group>
              <Form.Group  controlId="loginRegistrationRedirect">
                <Form.Text>
                  {t('login.no.access')}
                  <br />
                  {t('login.register.here')}
                  <Link to="/registrace"> zde</Link>
                </Form.Text>
              </Form.Group>
            </Form>
          </Card.Body>
      </StyledCard>

      <StyledModal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('login.forgotten.password.modal.header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('login.forgotten.password.modal.text.one')}
          <br />
          <br />
          {t('login.forgotten.password.modal.text.two')}
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Form onSubmit={handleSubmit(handleResetPassword)} className="modalForm">
            <Form.Row className="alignLeft">
              <Form.Control
                name="email"
                type="text"
                placeholder={t('login.forgotten.password.modal.insert.email')}
                ref={register}
                className={`form-control ${errors.email ? "is-invalid" : ""}`}>
              </Form.Control>
              <div className="invalid-feedback">{errors.email?.message}</div>
            </Form.Row>
            <Form.Group controlId="resetSubmit" className="alignRight">
                <Button variant="light" type="submit" className="submit-button">
                  {t('login.forgotten.password.modal.send.link')}
                </Button>
              </Form.Group>
          </Form>
        </Modal.Footer>
      </StyledModal>
    </Container>
  );
}

export default view(Login);
