import { store } from "@risingstack/react-easy-state";

const appStore = store({
  user: {},
  addContact(user) {
    appStore.user = user;
  },
  updateContact(info) {
    Object.keys(info).forEach((x) => (appStore.user[x] = info[x]));
  },
  deleteContact(userToDelete) {
    Object.keys(userToDelete).forEach((x) => delete appStore.user[x]);
  },
  event: {},
  addEvent(event) {
    appStore.event = event;
  }
});

export default appStore;
