import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { Container, FormCheck } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledContainer = styled(Container)`

  font-size: 1rem;

  .profile-photo {
    width: 200px;
    height: 200px;
    display: block;
    margin: 45px auto 45px auto;
  }

  .photo-upload {
    width: 35%;
    margin: 0 auto 30px auto;
  }

  .display-center {
    margin-left: auto;
    margin-right: auto;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA !important;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
    width: 33%;
    display: block;
    margin: 0 auto 0 auto;
  }

  .contact-info {
    background-color: #E3A51A;
    padding: 30px;
    border-radius: 15px;
    color: black;
    margin: 30px auto 30px auto;
  }

  input {
    border-radius: 15px;
  }

  .formCheck {
    padding: 1.5rem 0 0 2rem;
  }

  .checkbox {
    padding-left: 1.8rem;
  }
`;

function ProfileUserEdit() {
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    nickName: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.number()
      .nullable()
      .transform((v, o) => (o === "" ? null : v)),
    city: Yup.string(),
    postCode: Yup.number().nullable().transform((v, o) => (o === "" ? null : v)),
    phoneNumber: Yup
      .string()
      .nullable()
      .notRequired()
      .when('phoneNumber', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(phoneRegExp, 'Toto není validní telefonní číslo'),
    }),
    info: Yup.string().max(3000, 'Max 3000 characters'),
  }, [
    'phoneNumber', 'phoneNumber'
  ]);

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [selectedFile, setSelectedFile] = useState();
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const history = useHistory();
  let user;

  user = appStore.user
  const token = window.localStorage.getItem('userToken')

  // eslint-disable-next-line no-inner-declarations
  async function onSubmit(data) {
    const formData = new FormData();
    formData.append('photo', selectedFile);
    data.email = appStore.user.email;
    data.hideOnMap = !data.hideOnMap;
    formData.append('data', JSON.stringify(data));
    
    appStore.updateContact(data);
    
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
      }
    const response = await callBackend({ url: 'users/updateUser', payload: formData, headers: headers })
      if (response.error) {
        alert("Ajaj, stala se chyba");
        console.log(response)
        history.push({ pathname: "/profil-uzivatel" });
      } else {
      user.level = response.level;
      user.profilePictureUrl = response.profilePictureUrl;
      user.hideOnMap = response.hideOnMap;
      user.sendNotifications = response.sendNotifications;
      appStore.updateContact(user)
      alert("Děkujeme za doplnění údajů.");
      history.push({ pathname: "/home" });
      }
  }


  return (
    <StyledContainer>
        <Row>
        <Image className="profile-photo"
            src={user.profilePictureUrl || '../profile_avatar.png'} 
            roundedCircle={true} 
            alt="profilePhoto">
          </Image>  
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)} className="display-center">
          <Row>
                <input
                  name="photo"
                  type="file"
                  ref={register}
                  onChange={changeHandler}
                  className={`form-control photo-upload ${errors.info ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.info?.message}</div>
          </Row>
          <Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Control 
                placeholder={t('profile.name')}
                name="firstName"
                type="text"
                defaultValue={user.firstName}
                ref={register}
                className={`form-control ${errors.firstName ? "is-invalid" : ""
                  }`}
              />
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            </Col>
            <Col>
              <Form.Control 
                placeholder={t('profile.surname')}
                name="lastName"
                type="text"
                defaultValue={user.lastName}
                ref={register}
                className={`form-control ${errors.lastName ? "is-invalid" : ""
                  }`}
              />
              <div className="invalid-feedback">
                {errors.lastName?.message}
              </div>
            </Col>
          </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control 
              placeholder={t('profile.user.nickname')}
              name="nickName"
              type="text"
              defaultValue={user.nickName}
              ref={register}
              className={`form-control ${errors.nickName ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.nickName?.message}
              </div>
          </Form.Group>

          <Card className="contact-info">
            <Card.Title>{t('profile.contact.info')}</Card.Title>
            <Card.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.email')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name={t('profile.contact.info.email')}
                    type="text"
                    placeholder={user.email}
                    ref={register}
                    className={`form-control`}
                    disabled>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.telephone')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.telephone')} 
                    name="phoneNumber"
                    type="string"
                    defaultValue={user.phoneNumber}
                    ref={register}
                    className={`form-control ${errors.phoneNumber ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.phoneNumber?.message}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street')} 
                    name="street"
                    type="text"
                    defaultValue={user.street}
                    ref={register}
                    className={`form-control ${errors.street ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">{errors.street?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street.number')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street.number')}
                    name="streetNumber"
                    type="number"
                    defaultValue={user.streetNumber}
                    ref={register}
                    className={`form-control ${errors.streetNumber ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.streetNumber?.message}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.city')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.city')}
                    name="city"
                    type="text"
                    defaultValue={user.city}
                    ref={register}
                    className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.city?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.postcode')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                   placeholder={t('profile.contact.info.postcode')}
                   name="postCode"
                   type="number"
                   defaultValue={user.postCode}
                   ref={register}
                   className={`form-control ${errors.postCode ? "is-invalid" : ""
                     }`}
                 />
                 <div className="invalid-feedback">
                   {errors.postCode?.message}
                 </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.about')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.about.user.placeholder')}
                    name="info"
                    as="textarea"
                    defaultValue={user.info}
                    rows={4}
                    ref={register}
                    className={`form-control ${errors.info ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.info?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Row className="checkbox">
                  <FormCheck.Input 
                    name="hideOnMap"
                    type="checkbox"
                    ref={register}
                    id="hideOnMap"
                    defaultChecked={user.hideOnMap === 1 ? null : true}
                    />
                  <FormCheck.Label>
                  {t('profile.user.others.can.contact.me')}
                  </FormCheck.Label>
                </Form.Row>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Row className="checkbox">
                  <FormCheck.Input 
                    name="sendNotifications"
                    type="checkbox"
                    ref={register}
                    id="sendNotifications"
                    defaultChecked={user.sendNotifications ? user.sendNotifications : null}
                    />
                  <FormCheck.Label>
                  {t('profile.user.want.info.new.events')}
                  </FormCheck.Label>
                </Form.Row>
              </Form.Group>
            </Card.Body>
          </Card>
          <Button type="submit" className="submit-button mb-3">
            {t('profile.send')}
          </Button>
        </Form>
    </StyledContainer>
  );
}

export default view(ProfileUserEdit);
