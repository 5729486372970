import './App.css'
import Layout from './components/layout/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import UserRegistrationOneStepOnly from './pages/registration/userRegistrationOneStepOnly'
import ProfileUser from './pages/profileUser'
import ProfileOrganization from './pages/profileOrganization'
import ProfileUserEdit from './pages/profileUserEdit'
import ProfileOrgEdit from './pages/profileOrgEdit'
import Login from './pages/login'
import TermsOfUse from './pages/registration/termsOfUse'
import DataProtectionConditions from './pages/registration/personalDataProtection'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import EmailVerification from './pages/registration/emailVerification'
import { view } from '@risingstack/react-easy-state'
import PrivateRoute from './components/layout/privateRoute'
import AdminRoute from './components/layout/adminRoute'
import SimpleMap from './pages/map'
import axios from 'axios'
import appStore from './appStore'
import UserPasswordReset from './pages/resetPassword'
import { TranslateProvider } from './translate';
import Home from './pages/home'
import CreateEvent from './pages/createEvent'
import Events from './pages/events'
import VolunteerRegistration from './pages/volunteerRegistration'
import VolunteersPerEvent from './pages/volunteersPerEvent'
import MapNew from './pages/mapNew'
import Admin from './pages/admin'

function App() {
    const url = window.location.origin;
    if (!url.includes('localhost') && !url.includes('https')) {
      window.location = `https:${url.split(':')[1]}`
    }
    const apiUser = (process.env.REACT_APP_DOMAIN == 'http://localhost:3001' || process.env.REACT_APP_DOMAIN =='http://localhost:3000') ? 'http://localhost:3001/api/v1/users/get' : '/api/v1/users/get';
    const apiOrganization = (process.env.REACT_APP_DOMAIN == 'http://localhost:3001' || process.env.REACT_APP_DOMAIN =='http://localhost:3000') ? 'http://localhost:3001/api/v1/organizations/get' : '/api/v1/organizations/get';
    const user = appStore.user
    const token = window.localStorage.getItem('userToken')
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }
        if (token && !user.id) {
            axios
                .get(
                    apiUser,
                    { headers }
                )
                .then((res) => res.data)
                .then((json) => appStore.addContact(json))
                .catch(function (error) {
                    console.log(error);
                  })
        }

        if (token && !user.id) {
            axios
                .get(
                    apiOrganization,
                    { headers }
                )
                .then((res) => res.data)
                .then((json) => {
                    json.isOrg = true;
                    appStore.addContact(json);
                })
                .catch(function (error) {
                    console.log(error);
                  })
        }


    return (
        <TranslateProvider>
            <Router>
                <Switch>
                    <Route path="/" exact={true}>
                        <Login />
                    </Route>
                    <Route path="/registrace" exact={true}>
                        <UserRegistrationOneStepOnly />
                    </Route>
                    <Route path="/registraceOvereniEmailu/:token" exact={true}>
                        <EmailVerification />
                    </Route>
                    <Route path="/podminky-uzivani-sluzby" exact={true}>
                        <TermsOfUse />
                    </Route>
                    <Route path="/podminky-vyuziti-osobnich-udaju" exact={true}>
                        <DataProtectionConditions />
                    </Route>
                    <Route path="/login" exact={true}>
                        <Login />
                    </Route>
                    <Route path="/resetHesla/:token" exact={false}>
                        <UserPasswordReset />
                    </Route>
                    <Layout>
                        <PrivateRoute path="/profil-uzivatel" exact={true}>
                            <ProfileUser />
                        </PrivateRoute>
                        <PrivateRoute path="/profil-organizace" exact={true}>
                            <ProfileOrganization />
                        </PrivateRoute>
                        <PrivateRoute path="/profil-uzivatel/editace" exact={true}>
                            <ProfileUserEdit />
                        </PrivateRoute>
                        <PrivateRoute path="/profil-organizace/editace" exact={true}>
                            <ProfileOrgEdit />
                        </PrivateRoute>
                        <PrivateRoute path="/create-event" exact={true}>
                            <CreateEvent />
                        </PrivateRoute>
                        <PrivateRoute path="/volunteer-registration" exact={true}>
                            <VolunteerRegistration />
                        </PrivateRoute>
                        <PrivateRoute path="/volunteers-per-event" exact={true}>
                            <VolunteersPerEvent />
                        </PrivateRoute>
                        {/* <PrivateRoute path="/vlozit" exact={true}>
                            <AddServices />
                        </PrivateRoute> */}
                        <PrivateRoute path="/map" exact={true}>
                            <SimpleMap user={user} />
                        </PrivateRoute>
                        <PrivateRoute path="/home" exact={true}>
                            <Home user={user} />
                        </PrivateRoute>
                        <PrivateRoute path="/events" exact={true}>
                            <Events user={user} />
                        </PrivateRoute>
                        <PrivateRoute path="/mapNew" exact={true}>
                            <MapNew user={user} />
                        </PrivateRoute>
                        <AdminRoute path="/admin" exact={true}>
                            <Admin user={user} />
                        </AdminRoute>
                    </Layout>
                </Switch>
            </Router>
        </TranslateProvider>
    )
}

export default view(App)
