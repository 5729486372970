import React, {useState} from "react";
import { view } from "@risingstack/react-easy-state";
import { useHistory, Link } from "react-router-dom";
import appStore from "../appStore";
import { Container, Row, Col, Button } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import "../components/layout/layout.css";
import styled from 'styled-components';
import callBackend from "../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faHeart } from '@fortawesome/free-solid-svg-icons'
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';
import Modal from 'react-bootstrap/Modal'

const StyledContainer = styled(Container)`

  font-size: 1rem;

  .profile-photo {
    width: 200px;
    height: 200px;
    display: block;
    margin: 45px auto 45px auto;
  }

  .display-center {
    margin-left: auto;
    margin-right: auto;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA;
    color: white;
  }

  button {
    border-radius: 15px;
  }

  .reset-password {
    display: block;
    margin: 15px auto 15px auto;
    background-color: #C4C4C4;
    color: black;
    border-color: #C4C4C4;
  }

  .delete-profile {
    display: block;
    margin: 15px auto 15px auto;
    background-color: #dc3545;
    border-color: #dc3545;

      :hover {
          background-color: #e04a59;
          border-color: #e04a59;
      }
  }

  .contact-info {
    background-color: #E3A51A;
    padding: 30px;
    border-radius: 15px;
    color: black;
    margin: 30px auto 30px auto;
  }

  .contact-info .align-right {
    display: flex;
    flex-direction: row-reverse;
  }

  input {
    border-radius: 15px;
  }

  .heart-row {
    display: flex;
    justify-content: center;
  }
`;

const StyledModal = styled(Modal)`

  .modal-header {
    background-color: #dc3545;
    color: white;
    border-radius: 15px 15px 0 0;
  }

  .modal-content {
    text-align: left;
    border-radius: 15px !important;
  }

  .modal-footer {
    display: inline-flex;
    justify-content: space-between;
  }

  .modalForm {
    display: flex;
    justify-content: space-between !important;
  }

  .border-radius {
    border-radius: 15px !important;
  }
`;

function ProfileUser() {

  const history = useHistory();
  let user = appStore.user;

  const token = window.localStorage.getItem('userToken')
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const [show, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  
  // eslint-disable-next-line no-inner-declarations
  function logout() {
    appStore.user = {};
    window.localStorage.clear();
    history.push({ pathname: "/login" });
  }

  async function handleResetPassword() {
    const payload = { email: user.email }
    const result = await callBackend({ url: 'users/resetPassword', payload: payload })
    if (result.error) {
      alert("Nepodařilo se");
    } else {
      alert("Poslali jsme Vám email, Dozvíte se v něm, jak znovu nastavit Vaše heslo.");
      logout();
    }
  }

  async function handleDeleteAccount() {
    const payload = {
      id: user.id
    }
    const result = await callBackend({ url: 'users/closeAccount ', headers: headers, payload: payload })
    if (result.error) {
      alert("Nepodařilo se");
      console.log(result)
      history.push({ pathname: "/profil-uzivatel" });
    } else {
      alert("Účet smazán");
      history.push({ pathname: "/login" });
    }
    handleModalClose();
  }

  function redirectToProfile() {
    history.push({ pathname: "/profil-uzivatel" });
    handleModalClose();
  }
  
  return (
    <StyledContainer>
        <Row>
          <Image className="profile-photo"
            src={user.profilePictureUrl || 'profile_avatar.png'} 
            roundedCircle={true} 
            alt="profilePhoto">
            </Image>  
        </Row>
        <Row>
          <Form className="display-center">
            <Row className="mb-3">
              <Col>
                <Form.Control 
                  placeholder={user.firstName || t('profile.name')}
                  disabled
                />
              </Col>
              <Col>
                <Form.Control 
                  placeholder={user.lastName || t('profile.surname')}
                  disabled
                />
              </Col>
            </Row>
            <Form.Group className="mb-3">
                <Form.Control 
                  placeholder={user.nickName || t('profile.user.nickname')} 
                  disabled
                />
            </Form.Group>
            <Row>
                <Button size="sm" className="reset-password" onClick={handleResetPassword}>
                  {t('profile.change.password')}
                </Button>
              <Button size="sm" className="delete-profile" onClick={handleModalShow}>
                {t('profile.user.delete')}
              </Button>
            </Row>
          </Form>
        </Row>
        <Row className="contact-info">
          <Col>
            {t('profile.contact.info')}
          </Col>
          <Col className="align-right">
            <Link to="/profil-uzivatel/editace">
              <FontAwesomeIcon icon={faPencil} />
            </Link>
          </Col>
        </Row>
        <Row className="heart-row">
            {user.upvotes > 0 
            ? <div> 
                <FontAwesomeIcon 
                  icon={faHeart} 
                  color="#f2a196"
                  className="mr-3"/>
                {user.upvotes}
              </div>
            : <div> 
            <FontAwesomeIcon 
              icon={faHeart} 
              color="whitesmoke"
              className="mr-3"/>
            </div> }
          </Row>

        <StyledModal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title> {t('profile.user.delete.modal.header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('profile.user.delete.modal.body')}
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button variant="light" className="border-radius" onClick={redirectToProfile}>
            {t('profile.user.delete.modal.back')}
          </Button>
          <Button variant="danger" className="border-radius" onClick={handleDeleteAccount}>
            {t('profile.user.delete.modal.confirm')}
          </Button>
        </Modal.Footer>
      </StyledModal>
    </StyledContainer>
    );
  }

export default view(ProfileUser);
