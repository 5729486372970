import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { Container } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledContainer = styled(Container)`

  font-size: 1rem;

  .profile-photo {
    width: 200px;
    height: 200px;
    display: block;
    margin: 45px auto 45px auto;
  }

  .photo-upload {
    width: 35%;
    margin: 0 auto 30px auto;
  }

  .display-center {
    margin-left: auto;
    margin-right: auto;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA !important;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
    width: 33%;
    display: block;
    margin: 0 auto 0 auto;
  }

  .contact-info {
    background-color: #E3A51A;
    padding: 30px;
    border-radius: 15px;
    color: black;
    margin: 30px auto 30px auto;
  }

  input {
    border-radius: 15px;
  }

  select {
    border-radius: 15px;
  }
`;

function ProfileOrgEdit() {
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    website: Yup.string(),
    title: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.number()
      .nullable()
      .transform((v, o) => (o === "" ? null : v)),
    city: Yup.string(),
    postCode: Yup.number().nullable().transform((v, o) => (o === "" ? null : v)),
    ico: Yup.number().nullable().transform((v, o) => (o === "" ? null : v)),
    phoneNumber: Yup
      .string()
      .nullable()
      .notRequired()
      .when('phoneNumber', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(phoneRegExp, 'Toto není validní telefonní číslo'),
    }),
    info: Yup.string().max(3000, 'Max 3000 characters'),
  }, [
    'phoneNumber', 'phoneNumber'
  ]);

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [selectedFile, setSelectedFile] = useState();
    const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
    };

  const history = useHistory();
  let user = appStore.user;
  const token = window.localStorage.getItem('userToken')

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  const [orgType, setOrganizationType] = useState(user.organizationTypeId);

  // eslint-disable-next-line no-inner-declarations
  async function onSubmit(data) {
    const formData = new FormData();
    formData.append('photo', selectedFile);
    data.email = appStore.user.email;
    if (data.organizationTypeId === "0") data.organizationTypeId = null;
    formData.append('data', JSON.stringify(data));
    
    appStore.updateContact(data);
    
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
      }
    const response = await callBackend({ url: 'organizations/updateOrganization', payload: formData, headers: headers })
      if (response.error) {
        alert(response.error);
        history.push({ pathname: "/profil-organizace" });
      } else {
      const updatedLevel = response.level;
      const profilePictureUrl = response.profilePictureUrl;
      user.level = updatedLevel;
      user.profilePictureUrl = profilePictureUrl;
      appStore.updateContact(user)
      alert("Děkujeme za doplnění údajů.");
      history.push({ pathname: "/home" });
      }
  }


  return (
    <StyledContainer>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="display-center">
          <Row>
            <Image className="profile-photo"
              src={user.profilePictureUrl || '../building_avatar.png'} 
              roundedCircle={true} 
              alt="profilePhoto">
            </Image>
          </Row>
          <Row>
                <input
                  name="photo"
                  type="file"
                  ref={register}
                  onChange={changeHandler}
                  className={`form-control photo-upload ${errors.info ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.info?.message}</div>
          </Row>
          <Form.Group className="mb-3">
            <Form.Control 
              placeholder={user.title || t('profile.organization.title.placeholder') }
              name="title"
              type="text"
              defaultValue={user.title}
              ref={register}
              className={`form-control ${errors.title ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.title?.message}
              </div>
          </Form.Group>
          <Form.Label>{t('profile.organization.representative')}</Form.Label>
          <Row className="mb-3">
            <Col>
              <Form.Control 
                placeholder={t('profile.name')}
                name="firstName"
                type="text"
                defaultValue={user.firstName}
                ref={register}
                className={`form-control ${errors.firstName ? "is-invalid" : ""
                  }`}
              />
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            </Col>
            <Col>
              <Form.Control 
                placeholder={t('profile.surname')} 
                name="lastName"
                type="text"
                defaultValue={user.lastName}
                ref={register}
                className={`form-control ${errors.lastName ? "is-invalid" : ""
                  }`}
              />
              <div className="invalid-feedback">
                {errors.lastName?.message}
              </div>
            </Col>
          </Row>

          <Card className="contact-info">
            <Card.Title>{t('profile.contact.info')}</Card.Title>
            <Card.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.email')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder={user.email}
                    ref={register}
                    className={`form-control`}
                    disabled>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.web')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="website"
                    type="text"
                    defaultValue={user.website}
                    placeholder={t('event.create.web')}
                    ref={register}
                    className={`form-control`}
                    >
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.telephone')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.telephone')} 
                    name="phoneNumber"
                    type="number"
                    defaultValue={user.phoneNumber}
                    ref={register}
                    className={`form-control ${errors.phoneNumber ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.phoneNumber?.message}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street')}
                    name="street"
                    type="text"
                    defaultValue={user.street}
                    ref={register}
                    className={`form-control ${errors.street ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">{errors.street?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street.number')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street.number')}
                    name="streetNumber"
                    type="number"
                    defaultValue={user.streetNumber}
                    ref={register}
                    className={`form-control ${errors.streetNumber ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.streetNumber?.message}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.city')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.city')}
                    name="city"
                    type="text"
                    defaultValue={user.city}
                    ref={register}
                    className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.city?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.postcode')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                   placeholder={t('profile.contact.info.postcode')}
                   name="postCode"
                   type="number"
                   defaultValue={user.postCode}
                   ref={register}
                   className={`form-control ${errors.postCode ? "is-invalid" : ""
                     }`}
                 />
                 <div className="invalid-feedback">
                   {errors.postCode?.message}
                 </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.organization.contact.info.id')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.organization.contact.info.id')}
                    name="ico"
                    type="number"
                    defaultValue={user.ico}
                    ref={register}
                    className={`form-control ${errors.ico ? "is-invalid" : ""
                      }`}
                  />
                 <div className="invalid-feedback">
                   {errors.ico?.message}
                 </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.about')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.organization.contact.info.about.placeholder')}
                    name="info"
                    as="textarea"
                    defaultValue={user.info}
                    rows={4}
                    ref={register}
                    className={`form-control ${errors.info ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.info?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.organization.contact.info.type')}</Form.Label>
                <Col sm="10">
                  <Form.Control as="select"
                    name="organizationTypeId"
                    id="organizationTypeSelect"
                    ref={register}
                    defaultValue={user.organizationTypeId ? user.organizationTypeId.toString() : orgType || "0"}
                    onChange={e => {
                      setOrganizationType(e.target.value);
                  }}
                  >
                    <option value="0">{t('profile.organization.contact.info.type.choose')}</option>
                    <option value="1">{t('profile.organization.contact.info.type.seniors')}</option>
                    <option value="2">{t('profile.organization.contact.info.type.tourism')}</option>
                    <option value="3">{t('profile.organization.contact.info.type.community')}</option>
                    <option value="4">{t('profile.organization.contact.info.type.sport')}</option>
                    <option value="5">{t('profile.organization.contact.info.type.other')}</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
          <Form.Group>
            <Button variant="light" type="submit" className="submit-button mb-3">
              {t('profile.send')}
            </Button>
          </Form.Group>
        </Form>
    </StyledContainer>
  );
}

export default view(ProfileOrgEdit);
