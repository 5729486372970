import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";
import appStore from "../../appStore";
import { view } from "@risingstack/react-easy-state";

function AdminRoute({ children, ...rest }) {
  let userIsAuth = false;
  const userFromStorage =  window.localStorage.getItem('user')
  //userIsAuth = true;
  if (appStore.user.id && appStore.user.level === 3) {
    userIsAuth = true;
  } else if (userFromStorage.level === 3) {
    userIsAuth = true;
  } else{
        console.log(`admin route auth fail: appStore.user.level ${appStore.user.level}`)
        console.log(`admin route auth fail: userFromStorage.level ${userFromStorage.level}`)
        // alert("Je nutné být admin.");
      }
  return (
    <Route {...rest} render={() => {
      return userIsAuth === true
        ? children
        : <Redirect to={{
            pathname: '/home'
          }} />
    }} />
  )
}

export default view(AdminRoute)