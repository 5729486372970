import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";
import appStore from "../../appStore";
import { view } from "@risingstack/react-easy-state";

function PrivateRoute({ children, ...rest }) {
  let userIsAuth = false;
  let myStorage = window.localStorage;
  //userIsAuth = true;
  if (appStore.user.id) {
    userIsAuth = true;
  } else if (myStorage.getItem('userToken')) {
    userIsAuth = true;
  } else{
      console.log(`private route auth fail: appStore.user.id ${JSON.stringify(appStore.user.id)}`)
      console.log(`private route auth fail: localStorageToken ${myStorage.getItem('userToken')}`)
      // alert("Je nutné se přihlásit.");
      }
  return (
    <Route {...rest} render={() => {
      return userIsAuth === true
        ? children
        : <Redirect to={{
            pathname: '/login'
          }} />
    }} />
  )
}

export default view(PrivateRoute)