import React, { useEffect } from "react";
import { decode } from "jsonwebtoken";
import { useParams, useHistory, Link } from "react-router-dom";
import callBackend from "../../helpers"
import StyledCard from "../../components/layout/styledCard";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

export default function EmailVerification() {
  const history = useHistory();
  
  //jwt decode
  const { token } = useParams();
  const email = decode(token).email;
  const userInfoVerification = {};
  userInfoVerification.token = token;
  
  useEffect(() => {
    async function verifyUser(userInfoVerification) {
      const result = await callBackend({ url: 'users/verify', payload: userInfoVerification });
      if (result.error) {
        alert("Overeni emailu se na BE nezdarilo");
        console.log(result)
        history.push({ pathname: "/" });
      } 
    }

    verifyUser(userInfoVerification);
  });

  return (
    <Container>
      <StyledCard>
        <Card.Body>
          <Card.Title className="subtitle">Ověření {email} proběhlo úspěšně! 🎉</Card.Title>
          <Card.Text>
            Prosím, pokračujte na <Link to="/login"> přihlášení </Link>
          </Card.Text>
          </Card.Body>
      </StyledCard>
    </Container>
  );
}
