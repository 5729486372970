import React, { useState, useEffect } from 'react';
import { Container, Table } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import { view } from "@risingstack/react-easy-state";
// import Button from 'react-bootstrap/Button';
import appStore from '../appStore';
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledContainer = styled(Container)`
    font-size: 16px;

    p {
        text-align: left;
        margin-top: 60px;
    }
    
    .table {
      margin-top: 20px;
      border-radius: 20px;
    }
    .table thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: whitesmoke;
    }
`;

function VolunteersPerEvent() {
    const token = window.localStorage.getItem('userToken')
    // to persist value with refresh
    if (appStore.event.id) window.localStorage.setItem('event', JSON.stringify(appStore.event));
    const event = JSON.parse(window.localStorage.getItem('event'));

    const [volunteers, setVolunteers] = useState([]);

    const { t } = useTranslate();
    setCurrentLanguage(window.localStorage.getItem('language'))

    const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    };

    async function fetchVolunteers() {
      const volunteers = await callBackend({ method: 'POST', url: 'organizations/event/users', headers: headers, payload: event });
      if (volunteers.error) {
        alert("Ajaj, stala se chyba");
        console.log(volunteers)
      } else {
        setVolunteers(volunteers);
      }
    }
  
    useEffect(() => {
      fetchVolunteers();
    }, [])
  
    const volunteersTableRows = volunteers
    .map((volunteer, index) => <tr key={index}>
        <td>{volunteer.firstName}</td>
        <td>{volunteer.lastName}</td>
        <td>{volunteer.city}</td>
        <td>{volunteer.email}</td>
        <td>{volunteer.title}</td>
        <td>{volunteer.info}</td>
        <td>{volunteer.maxVolunteers}</td>
    </tr>)
    || null;
  
  
    return (
    <StyledContainer>
        <p>{t('home.event.helpers.list')}: {event.name}</p>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>{t('profile.name')}</th>
              <th>{t('profile.surname')}</th>
              <th>{t('home.event.city')}</th>
              <th>{t('profile.contact.info.email')}</th>
              <th>{t('home.event.help.type')}</th>
              <th>{t('home.event.help.info')}</th>
              <th>{t('home.event.help.maxVolunteers')}</th>
            </tr>
          </thead>
          <tbody>
            {volunteersTableRows}
          </tbody>
        </Table>
    </StyledContainer>
    );
  
  }
  

export default view(VolunteersPerEvent);