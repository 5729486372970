import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import callBackend from "../helpers"
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import styled from 'styled-components';
import { decode } from "jsonwebtoken";
import { useParams, useHistory } from "react-router-dom";
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 15px;

  .title {
    font-size: 45px;
    color: #4F4F4F;
    letter-spacing: 3px;
  }

  .subtitle {
    color: #4F4F4F;
    margin-bottom: 20px;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA;
    color: white;
  }

  button {
    border-radius: 15px;
  }

  input {
    border-radius: 15px;
  }
`;

export default function UserPasswordReset() {

    const history = useHistory();

    //jwt decode
    const { token } = useParams();
    const email = decode(token).email;

    // form validation rules
    const validationSchema = Yup.object().shape({
        password: Yup.string()
        .min(6, "Heslo musí mít alespoň 6 znaků")
        .required("Heslo je povinné"),
        confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Hesla se musí shodovat")
        .required("Potvrzení hesla je povinné"),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, errors } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const { t } = useTranslate();
    setCurrentLanguage(window.localStorage.getItem('language'))


    async function onSubmit(data) {
        const payload = {
            email: email,
            password: data.password
        }
            const result = await callBackend({ url: 'users/newPassword', payload: payload })
            if (result.error) {
                alert("Nepodařilo se, zkuste to, prosím znovu / kontaktujte nás");
                history.push({ pathname: "/login" });
            } else {
                alert("Změna hesla proběhla úspěšně!");
                history.push({ pathname: "/login" });
            }
    }

  return (
    <Container>
      <StyledCard>
        <Card.Body>
          <Card.Title className="title">{t('login.forgotten.password')}</Card.Title>
           <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <Form.Group controlId="resetEmail">
                <Form.Row>
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder={email}
                    ref={register}
                    className={`form-control`}
                    disabled>
                  </Form.Control>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="resetPassword">
                <Form.Row>
                  <Form.Control
                      name="password"
                      type="password"
                      placeholder={t('login.forgotten.password.new')}
                      ref={register}
                      className={`form-control ${errors.password ? "is-invalid" : ""
                        }`}>
                  </Form.Control>
                  <div className="invalid-feedback">{errors.password?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="confirmResetPassword">
                <Form.Row>
                  <Form.Control
                      name="confirmPassword"
                      type="password"
                      placeholder="heslo pro kontrolu znovu"
                      ref={register}
                      className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
                    }`}>
                  </Form.Control>
                  <div className="invalid-feedback"> {errors.confirmPassword?.message}</div>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="resetPasswordSubmit">
                <Button variant="light" type="submit" className="submit-button" onClick={handleSubmit(onSubmit)}>
                  {t('profile.change.password')}
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
      </StyledCard>
    </Container>
  );
}
