import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { Container } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/de';
import { useTranslate } from "../translate";
import { getCurrentLanguage, setCurrentLanguage } from '../translate/Translate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";

const StyledContainer = styled(Container)`

  font-size: 1rem;

  .photo-upload {
    margin: 0 auto 30px auto;
  }

  .display-center {
    margin-left: auto;
    margin-right: auto;
  }

  .textarea-border-radius {
    border-radius: 15px !important;
  }

  .submit-button {
    margin: 30px 0 15px 0;
    background-color: #6EB6BA !important;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
    width: 33%;
    display: block;
    margin: 0 auto 0 auto;
  }

  .event-info {
    background-color: #E3A51A;
    padding: 30px;
    border-radius: 15px;
    color: black;
    margin: 30px auto 30px auto;
  }

  input {
    border-radius: 15px;
  }

  p {
    text-align: left;
    font-style: italic;
    font-weight: bold;
  }

  .alert-heading {
    font-size: 1.2rem;
  }

  .alert {
    text-align: center;

    p {
      font-size: 1rem;
      margin-bottom: 0px;
      font-weight: 100;
      font-style: normal;
    }

    a {
      font-size: 1rem;
    }
  }
`;

function CreateEvent() {

  const user = appStore.user;
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (user.level < 2) setShowAlert(true);
  }, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Název je povinný"),
    info: Yup.string(),
    website: Yup.string(),
    street: Yup.string().required("Adresa je povinná"),
    streetNumber: Yup.number()
      .nullable()
      .transform((v, o) => (o === "" ? null : v)),
    city: Yup.string().required("Adresa je povinná"),
    postCode: Yup.number().nullable().transform((v, o) => (o === "" ? null : v)),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      eventsHelpTypes: [{title: '', info: '', maxVolunteers: 0}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'eventsHelpTypes', // unique name for your Field Array
  });

  const [selectedFile, setSelectedFile] = useState();
  const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
    };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleStartDate = (date) => {
    setStartDate({date});
    };

  const handleEndDate = (date) => {
    setEndDate({date});
    };

  const history = useHistory();
  const token = window.localStorage.getItem('userToken')

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))
  function getCalendarLanguage() {
    const currentLanguage = getCurrentLanguage();
    const calendarLang = !currentLanguage ? 'cs' : (currentLanguage === 'cz') ? 'cs' : 'de';
    return calendarLang
  }

  // eslint-disable-next-line no-inner-declarations
  async function onSubmit(data) {
    const formData = new FormData();
    formData.append('photo', selectedFile);
    data.email = appStore.user.email;
    data.organization = appStore.user;
    data.startDate = moment(startDate?.date).toISOString();
    data.endDate = moment(endDate?.date).toISOString();
    data.photo = undefined;
    formData.append('data', JSON.stringify(data));
    
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
      }
    const response = await callBackend({ url: 'organizations/createEvent', payload: formData, headers: headers })
      if (response.error) {
        alert('Ajaj, stala se chyba při vytváření události.')
        console.log(response);
        history.push({ pathname: "/profil-organizace" });
      } else {
      // to be nicer with frontend design review
      // TODO check, if everything was fill-in and adjust the alert accordingly!
      alert("Událost byla vytvořena");
      history.push({ pathname: "/home" });
      }
  }


  return (
    <StyledContainer>
      {showAlert &&  
        <Alert variant='danger'>
            <Alert.Heading>{t('map.hidden.title')}</Alert.Heading>
            <hr />
            <p>{t('map.hidden.text')}</p>
            <Link to="/profil-organizace">{t('map.hidden.link')}</Link>
        </Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="display-center">
          <Card className="event-info">
            <Card.Title>{t('event.create')}</Card.Title>
            <Card.Body>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.name')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder={t('event.create.name.placeholder')}
                    ref={register}
                    className={`form-control`}
                    >
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.description')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="info"
                    as="textarea"
                    rows={3}
                    placeholder={t('event.create.description.placeholder')}
                    ref={register}
                    className={`form-control textarea-border-radius`}
                    >
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.photo')}</Form.Label>
                <Col sm="10">
                <input
                  name="photo"
                  type="file"
                  ref={register}
                  onChange={changeHandler}
                  className={`form-control photo-upload`}
                />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.place.date')}, {t('event.create.place.date.from')}</Form.Label>
                <Col sm="10">
                  <Datetime
                    name="startDate"
                    onChange={handleStartDate}
                    ref={register}
                    locale={getCalendarLanguage()}
                    required
                    >
                  </Datetime>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.place.date')}, {t('event.create.place.date.to')}</Form.Label>
                <Col sm="10">
                  <Datetime
                    name="endDate"
                    onChange={handleEndDate}
                    ref={register}
                    locale={getCalendarLanguage()}
                    required
                    >
                  </Datetime>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.web')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="website"
                    type="text"
                    placeholder="www.abcd.cz"
                    ref={register}
                    className={`form-control`}
                    >
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street')} 
                    name="street"
                    type="text"
                    ref={register}
                    className={`form-control ${errors.street ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">{errors.street?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.street.number')} </Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('profile.contact.info.street.number')} 
                    name="streetNumber"
                    type="number"
                    ref={register}
                    className={`form-control ${errors.streetNumber ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.streetNumber?.message}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('home.event.city')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('home.event.city')}
                    name="city"
                    type="text"
                    ref={register}
                    className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.city?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('profile.contact.info.postcode')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                   placeholder={t('profile.contact.info.postcode')}
                   name="postCode"
                   type="number"
                   ref={register}
                   className={`form-control ${errors.postCode ? "is-invalid" : ""
                     }`}
                 />
                 <div className="invalid-feedback">
                   {errors.postCode?.message}
                 </div>
                </Col>
              </Form.Group>
              <p>{t('event.create.activity.title')}</p>

              {fields.map((field, index) => (
              <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.activity.card')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('event.create.activity.name')}
                    name={`eventsHelpTypes.${index}.title`}
                    as="textarea"
                    rows={1}
                    key={field.id}
                    ref={register()}
                    className={`form-control textarea-border-radius  ${errors.activityTitle ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.activityTitle?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.activity.description')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder={t('event.create.activity.description')}
                    name={`eventsHelpTypes.${index}.info`}
                    as="textarea"
                    rows={4}
                    key={field.id}
                    ref={register()}
                    className={`form-control textarea-border-radius  ${errors.activityInfo ? "is-invalid" : ""}`}
                  />
                 <div className="invalid-feedback">{errors.activityInfo?.message}</div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">{t('event.create.activity.volunteers.number')}</Form.Label>
                <Col sm="10">
                  <Form.Control
                    name={`eventsHelpTypes.${index}.maxVolunteers`}
                    placeholder={t('event.create.activity.volunteers.placeholder')}
                    type="number"
                    min="0"
                    key={field.id}
                    ref={register()}
                    className={`form-control ${errors.maxVolunteers ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.maxVolunteers?.message}
                  </div>
                </Col>
              </Form.Group>
              <Button 
                  variant='danger'
                  onClick={() =>{remove(index)}}
                >
                  <FontAwesomeIcon icon={faMinus}/>
                </Button>
              <hr />
              </>
              ))}
              <Button 
                  variant='success'
                  onClick={() => {
                    append({ volunteerActivityTitle: '', volunteerActivityInfo: '', maxVolunteers: 0 });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </Card.Body>
          </Card>
          <Form.Group>
            {showAlert 
            ? <Button disabled variant="light" type="submit" className="submit-button mb-3">
                {t('event.create.submit')}
              </Button>
            : <Button variant="light" type="submit" className="submit-button mb-3">
                {t('event.create.submit')}
              </Button>}
          </Form.Group>
        </Form>
    </StyledContainer>
  );
}

export default view(CreateEvent);
