import React from "react";
import { Link } from "react-router-dom";

export default function DataProtectionConditions() {
  return (
    <div className="m-3">

      <h5>Für Deutsch, bitte scrollen Sie nach unten</h5>

      <h3>Souhlasím s užitím osobních údajů</h3>

        <p className="textLeft">
          Tímto udělujete souhlas společnosti Seniore, s. r. o., se sídlem
          Bratislavská 2323, 407 47 Varnsdorf, IČ: 08025452, zapsané ve veřejném
          rejstříku vedeném u Krajského soudu v Ústí nad Labem,číslo listiny C
          43383/SL1/KSUL (dále jen „Správce“), aby ve smyslu nařízení Evropského
          parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v
          souvislosti se zpracováním osobních údajů a o volném pohybu těchto
          údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně
          osobních údajů), (dále jen „Nařízení“) zpracovávala tyto osobní údaje:
          jméno a příjmení, adresa bydliště, e-mail, telefonní číslo,
          fotografie, datum narození, kopie občanského průkazu.{" "}
        </p>
        <p className="textLeft">
          {" "}
          Jméno, příjmení, adresu bydliště, e-mail, telefonní číslo, datum
          narození, kopii občanského průkazu a fotografii je možné zpracovat na
          základě Vámi uděleného souhlasu a je nutné je zpracovat za účelem
          registrace a následného využívání služby Seniore. Tyto údaje budou
          Správcem zpracovány po dobu využívání služby Seniore uživatelem.{" "}
        </p>
        <p className="textLeft">
          {" "}
          S výše uvedeným zpracováním udělujete svůj výslovný souhlas.
          Poskytnutí osobních údajů je dobrovolné. Souhlas lze vzít kdykoliv
          zpět, a to zasláním e-mailu na adresu contact@seniore.org.{" "}
        </p>
        <p className="textLeft">
          {" "}
          Vezměte, prosím, na vědomí, že podle Nařízení máte právo:{" "}
        </p>
        <ul>
          <li>vzít souhlas kdykoliv zpět, </li>
          <li>
            požadovat po nás informaci, jaké vaše osobní údaje zpracováváme,
            žádat si kopii těchto údajů,
          </li>
          <li>
            vyžádat si u nás přístup k těmto údajům a tyto nechat aktualizovat
            nebo opravit, popřípadě požadovat omezení zpracování,
          </li>
          <li>požadovat po nás výmaz těchto osobních údajů,</li>
          <li>
            podat stížnost u Úřadu pro ochranu osobních údajů nebo se obrátit na
            soud.
          </li>
        </ul>
        <p className="textLeft">
          {" "}
          Vámi poskytnuté osobní údaje nebudou předmětem automatizovaného
          rozhodování ani profilování.{" "}
        </p>
        <p className="textLeft">
          {" "}
          <b>
            Zpět na <Link to="/registrace">registraci </Link>.
          </b>
        </p>

        <hr></hr>

      <h3>Ich bin mit der Verwendung meiner persönlichen Daten einverstanden</h3>

        <p className="textLeft">
          Sie erteilen hiermit Seniore, s. r. o., mit Sitz in der Schweiz, Ihre Zustimmung.
          Bratislavská 2323, 407 47 Varnsdorf, ID: 08025452, eingetragen im öffentlichen Register
          Register, das beim Bezirksgericht in Ústí nad Labem geführt wird, Urkundennummer C
          43383/SL1/KSUL (nachstehend "Verwalter" genannt), an
          2016/679 des Europäischen Parlaments und des Rates über den Schutz natürlicher Personen in
          die Verarbeitung personenbezogener Daten und den freien Verkehr dieser Daten
          Daten und zur Aufhebung der Richtlinie 95/46/EG (Allgemeine Datenschutzverordnung)
          Personenbezogene Daten) (nachstehend "Verordnung" genannt) zur Verarbeitung dieser personenbezogenen Daten:
          Vor- und Nachname, Wohnanschrift, E-Mail, Telefonnummer,
          Lichtbild, Geburtsdatum, Kopie des Personalausweises.{" "}
        </p>
        <p className="textLeft">
          {" "}
          Vorname, Nachname, Wohnanschrift, E-Mail, Telefonnummer, Datum
          Geburt, Kopie des Personalausweises und Foto können bearbeitet werden bei
          auf der Grundlage Ihrer Einwilligung und müssen zu folgenden Zwecken verarbeitet werden
          die Registrierung und anschließende Nutzung des Seniore-Dienstes. Diese Daten werden
          vom Administrator für die Dauer der Nutzung des Seniore-Dienstes durch den Nutzer verarbeitet.{" "}
        </p>
        <p className="textLeft">
          {" "}
          Sie geben Ihre ausdrückliche Zustimmung zu der oben genannten Verarbeitung.
          Die Angabe von personenbezogenen Daten ist freiwillig. Die Zustimmung kann jederzeit widerrufen werden
          indem Sie eine E-Mail an contact@seniore.org senden.{" "}
        </p>
        <p className="textLeft">
          {" "}
          Bitte beachten Sie, dass Sie nach der Verordnung das Recht haben:{" "}
        </p>
        <ul>
          <li>die Zustimmung jederzeit widerrufen, </li>
          <li>
            von uns Auskunft darüber verlangen, welche personenbezogenen Daten wir über Sie verarbeiten,
            eine Kopie dieser Daten anfordern,
          </li>
          <li>
            den Zugang zu diesen Daten bei uns beantragen und sie aktualisieren lassen
            oder zu berichtigen oder eine Einschränkung der Verarbeitung zu verlangen,
          </li>
          <li>uns auffordern, diese personenbezogenen Daten zu löschen,</li>
          <li>
            eine Beschwerde beim Amt für den Schutz personenbezogener Daten einreichen oder sich an
            Gericht.
          </li>
        </ul>
        <p className="textLeft">
          {" "}
          Die von Ihnen zur Verfügung gestellten personenbezogenen Daten werden nicht automatisiert
          Entscheidungsfindung oder Profiling.{" "}
        </p>
        <p className="textLeft">
          {" "}
          <b>
            Zurück zu <Link to="/registrace">Anmeldung </Link>.
          </b>
        </p>
    </div>
  );
}
