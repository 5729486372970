import React, { useState, useEffect } from 'react';
import { Container, Table } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
// import appStore from '../appStore';
import moment from 'moment';
import { view } from "@risingstack/react-easy-state";
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";
import appStore from '../appStore';
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faImage, 
} from '@fortawesome/free-solid-svg-icons';

const StyledContainer = styled(Container)`
    font-size: 14px;
    
    img {
      max-width: 100px;
      max-height: 100px;
    }

    .table-container {
      margin-top: 20px;
      border-radius: 20px;
      max-height: 400px;
      padding: 0 10px 10px 10px;
      overflow: scroll;
    }

    .table {
      text-align: center;
    }

    .table td {
      vertical-align: middle;
    }

    .table tbody tr {
      :hover {
          background-color: #f8e6bf;
        }
    }
    
    .table thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #274C77;
      color: whitesmoke;
    }

    .volunteer-button {
        font-size: small;
        background-color: #28a745;
        border-color: #28a745;

        :hover {
            background-color: #3DAF57;
            border-color: #3DAF57;
        }
    }

    .cancel {
      background-color: #f84c23;
      border-color: #f84c23;

      :hover {
          background-color: #f95e39;
          border-color: #f95e39;
      }
    }

    .disabled {
      font-size: small;
      background-color: #7b7b7b;
      border-color: #7b7b7b;
    }
`;

function Events() {
    // const user = appStore.user;
    let user = {}
    if (appStore.user) {
      user = appStore.user
    } else user = JSON.parse(window.localStorage.getItem('user'));
    const history = useHistory();
    const token = window.localStorage.getItem('userToken')
    const [futureEventsArray, setFutureEventsArray] = useState([]);
    const [pastEventsArray, setPastEventsArray] = useState([]);
    const [userFutureEvents, setUserFutureEvents] = useState([]);


    const { t } = useTranslate();
    setCurrentLanguage(window.localStorage.getItem('language'))

    async function registerVolunteerRedirect(eventPlanned) {
        history.push({ pathname: "/volunteer-registration"})
        window.localStorage.setItem('event', JSON.stringify(eventPlanned));
        appStore.addEvent(eventPlanned)
      }
    
    function profileRedirect() {
        history.push({ pathname: "/profil-uzivatel"})
      }

    const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    };
    async function fetchEvents() {
    const data = await callBackend({ method: 'GET', url: 'organizations/allEvents', headers: headers });
      if (data.error) {
        alert('Ajaj, stala se chyba při načítání všech událostí.')
        console.log(data);
      } else {
      const now = moment().local();
      const futureEvents = data.filter((x)=>moment(x.startDate).local()>now);
      futureEvents.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setFutureEventsArray(futureEvents);
      const pastEvents = data.filter((x)=>moment(x.startDate).local()<now);
      pastEvents.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
      setPastEventsArray(pastEvents);
      }
    }

    async function userEvents() {
      const data = await callBackend({ method: 'GET', url: 'users/events', headers: headers });
      if (data.error) {
        // alert('Při načítání událostí se stala chyba.')
        console.log(data);
      } else {
        const now = moment().local();
        const userFutureEvents = data.filter((x)=>moment(x.startDate).local()>now);
        userFutureEvents?.sort((a,b)=>new Date(a.startDate)-new Date(b.startDate).valueOf());
        setUserFutureEvents(userFutureEvents);
      }
    }

    async function cancelEventRegistration(event) {
      const data = await callBackend({ method: 'POST', url: 'users/removeEvent', headers: headers, payload: event });
      if (data.error) {
        // alert('Při načítání událostí se stala chyba.')
        console.log(data);
      } else {
        const updatedUserEvents = userFutureEvents.filter((x) => x.id !== event.id)
        setUserFutureEvents(updatedUserEvents)
        alert('Byl jste úspěšně odhlášen')
      }
    }
  
    useEffect(() => {
      fetchEvents();
      userEvents();
    }, [])
  
    const futureEventsTableRows = futureEventsArray
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.title}</td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
        {user.isOrg 
          ? null
          :  user.level < 2 
            ? <td><Button size="sm" className='disabled' onClick={() => profileRedirect()}>{t('event.registration.disabled')}</Button></td>
            : (!userFutureEvents.filter((x) => x.id === eventPlanned.id).length ) 
              ? <td><Button size="sm" className='volunteer-button' onClick={() => registerVolunteerRedirect(eventPlanned)}>{t('event.registration.activity.submit')}</Button></td>
              : <td><Button size="sm" className='volunteer-button cancel' onClick={() => cancelEventRegistration(eventPlanned)}>{t('event.registered.cancel')}</Button></td>
          }
        </tr>)
     
    const pastEventsTableRows = pastEventsArray
    .map((eventPlanned, index) => <tr key={index}>
        <td><img src={eventPlanned.profilePictureUrl}/></td>
        <td>{eventPlanned.title}</td>
        <td>{eventPlanned.name}</td>
        <td>{eventPlanned.city}</td>
        <td>{moment(eventPlanned.startDate).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(eventPlanned.endDate).format('DD/MM/YY HH:mm')}</td>
        <td>{eventPlanned.info}</td>
        {eventPlanned.website 
          ? <td><a target="_blank" rel="noreferrer" href={eventPlanned.website}>{t('event.info.link')}</a></td>
          : <td><p>{t('event.info.no.link')}</p></td>
        }
    </tr>)
    || null;
  
    return (
    <StyledContainer>
        <h4>{t('future.events')}</h4>
        <div className='table-container'>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <FontAwesomeIcon 
                    icon={faImage} 
                    color="whitesmoke"/>
                </th>
                <th>{t('home.organization.title')}</th>
                <th>{t('event.create.name.placeholder')}</th>
                <th>{t('home.event.city')}</th>
                <th>{t('event.create.place.date.from')}</th>
                <th>{t('event.create.place.date.to')}</th>
                <th>{t('home.event.help.info')}</th>
                <th>{t('home.event.web')}</th>
                {!user.isOrg && 
                <th>{t('event.registration.title')}</th>}
              </tr>
            </thead>
            <tbody>
              {futureEventsTableRows}
            </tbody>
          </Table>
        </div>

        <h4 className='mt-4'>{t('past.events')}</h4>
        <div className='table-container'>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th><FontAwesomeIcon 
                    icon={faImage} 
                    color="whitesmoke"/>
                </th>
                <th>{t('home.organization.title')}</th>
                <th>{t('event.create.name.placeholder')}</th>
                <th>{t('home.event.city')}</th>
                <th>{t('event.create.place.date.from')}</th>
                <th>{t('event.create.place.date.to')}</th>
                <th>{t('home.event.help.info')}</th>
                <th>{t('home.event.web')}</th>
              </tr>
            </thead>
            <tbody>
              {pastEventsTableRows}
            </tbody>
          </Table>
        </div>
    </StyledContainer>
    );
  
  }
  

export default view(Events);