import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import appStore from "../appStore";
import { Container, Row, Col } from "react-bootstrap";
import callBackend from "../helpers";
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FormCheck } from "react-bootstrap";
import StyledCard from "../components/layout/styledCard";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faClock,  
  faLocationDot, 
  faBuilding,
  faFile,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslate } from "../translate";
import { setCurrentLanguage } from '../translate/Translate';

const StyledCardRegistration = styled(StyledCard)`

  .card-title {
    text-align: left;
  }

  .registrationRadioField {
    background-color: white;
    text-align: left;
    border-radius: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .formCheck {
    padding: 1rem 0 0 2rem;
  }

  .acceptTerms {
    padding-left: 1.8rem;
  }
`;

const StyledContainer = styled(Container)`
  font-size: 16px;
  
  h3 {
    text-align: left;
  }

  p {
    text-align: left;
  }

  img {
    max-height: 200px;
    max-width: 200px;
    border-radius: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
`;

function VolunteerRegistration() {

  const history = useHistory();
  const token = window.localStorage.getItem('userToken')
  // to persist value with refresh
  if (appStore.event.id) window.localStorage.setItem('event', JSON.stringify(appStore.event));
  const event = JSON.parse(window.localStorage.getItem('event'));
  let user = {}
  if (appStore.user) {
    user = appStore.user
  } else user = JSON.parse(window.localStorage.getItem('user'));


  const validationSchema = Yup.object().shape({
    acceptGDPR: Yup.bool().oneOf([true], "Souhlas se zpracováním osobních údajů je nutný")
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { t } = useTranslate();
  setCurrentLanguage(window.localStorage.getItem('language'))

  // eslint-disable-next-line no-inner-declarations
  async function onSubmit(data) {
    data.email = user.email;
    data.eventId = event.id;
    data.userId = user.id;
    data.organizationId = event.organizationId;
    
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': "application/json"
      }
    const response = await callBackend({ url: 'users/addEvent', payload: data, headers: headers })
      if (response.error) {
        alert("Ajaj, stala se chyba");
        console.log(response)
        window.localStorage.removeItem('event');
        history.push({ pathname: "/events" });
      } else {
      alert("Registrace proběhla úspěšně");
        window.localStorage.removeItem('event');
        history.push({ pathname: "/home" });
      }
  }


  return (
    <StyledContainer>
      <Row>
        <Col>
          <h3>{t('event.registration.success.title')}</h3>
          <p>{t('event.registration.subtitle')}</p>
        </Col>
        <Col><img src={event.profilePictureUrl}/></Col>
      </Row>
      <Row className="ml-0">
        <FontAwesomeIcon 
          icon={faFile} 
          className="mr-3 mb-3"/>
         {event.name}
      </Row>
      <Row className="ml-0">
        <FontAwesomeIcon 
          icon={faCircleInfo} 
          className="mr-3 mb-3"/>
         {event.info}
      </Row>
      <Row className="ml-0">
        <FontAwesomeIcon 
          icon={faClock} 
          className="mr-3 mb-3"/>
         {moment(event.startDate).format('DD/MM/YY HH:mm')} - {moment(event.endDate).format('DD/MM/YY HH:mm')}
      </Row>
      <Row className="ml-0">
        <FontAwesomeIcon 
          icon={faLocationDot} 
          className="mr-3 mb-3"/>
         {event.city}
      </Row>
      <Row className="ml-0">
        <FontAwesomeIcon 
          icon={faBuilding} 
          className="mr-3 mb-3"/>
         {event.title}
      </Row>
      <StyledCardRegistration className="event-info">
        <StyledCardRegistration.Title className="mt-3">{t('event.registration.activity.choose')}</StyledCardRegistration.Title>
        <StyledCardRegistration.Body>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        
          <Form.Group>
          {event.eventsHelpTypes.map((helptype, index) => (
          <>
            <Form.Row className="registrationRadioField">
              <FormCheck className="formCheck">
                <FormCheck.Input
                  name="eventsHelpTypeId"
                  type="radio"
                  ref={register}
                  id={`eventHelpType.${index}`}
                  key={index}
                  value={helptype.id}
                  className={`form-check-input ${errors.eventRegistrationActivity ? "is-invalid" : ""
                    }`}
                  />
                <FormCheck.Label className="radioInputText">
                  {/* TODO: fix and rewrite on Activity name 1 */}
                {helptype.title} 
                </FormCheck.Label>
                <div className="invalid-feedback"> {errors.eventRegistrationActivity?.message}</div>
                <div>
                  <hr />
                  {helptype.info}
                </div>
              </FormCheck>
            </Form.Row>
           </>
           ))}
            <Form.Row className="acceptTerms">
              <FormCheck.Input 
                name="acceptGDPR"
                type="checkbox"
                ref={register}
                id="acceptGDPR"
                className={`form-check-input ${errors.acceptGDPR ? "is-invalid" : ""
                  }`}
                />
              <FormCheck.Label>
                {t('event.registration.activity.gdpr')}
              </FormCheck.Label>
              <div className="invalid-feedback"> {errors.acceptGDPR?.message}</div>
            </Form.Row>
          </Form.Group>
          <Form.Group controlId="registrationSubmit">
            <Button variant="light" type="submit" className="submit-button">
              {t('event.registration.activity.submit')}
            </Button>
          </Form.Group>
        </Form>
        </StyledCardRegistration.Body>
      </StyledCardRegistration>
    </StyledContainer>
  );
}

export default view(VolunteerRegistration);
